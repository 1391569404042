import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import Select from 'react-select';
import { CSS } from '@dnd-kit/utilities';
import { IconChevronDown, IconGripVertical } from '@tabler/icons-react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import useTheme from 'chat-ui/src/hooks/useTheme';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { LangContext } from '@/context/LangProvider';
import { cn } from '@/lib/utils';
import useQuestionsStore from '@/store/ScriptQuestionsStore';
import probingQuestions from './components/probingQuestionsList';
import QuestionMediaUploading from './components/QuestionMediaUploading';
import useCreateProjectStore from '@/store/CreateProjectStore';
import SparklesIcon from '@/components/icons/SparklesIcon';
import getTextColor from '@/utils/getTextColorContrast';

type FileDataState = {
  [key: string]: IQuestionsFilesData;
};

interface IQuestionBox {
  id: string;
  register: UseFormRegister<FieldValues>;
  index: number;
  control: Control<FieldValues>;
  handleDeleteQuestion: (idToDelete: string) => void;
  isActiveDrag: boolean;
  isUserViewer: boolean;
  existingText: string | undefined;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  singleProjectData: IProjectProps;
  questions: IQuestionBoxProps[];
  openQuestionId: string | null;
  setOpenQuestionId: React.Dispatch<React.SetStateAction<string | null>>;
  setFileData: React.Dispatch<React.SetStateAction<{ [key: string]: IQuestionsFilesData }>>;
  fileData: FileDataState;
}

function QuestionBox({ id, register, index, control,
  handleDeleteQuestion, isActiveDrag, isUserViewer, existingText,
  isProjectPublished, questions, singleProjectData,
  isProjectCompleted, openQuestionId, setOpenQuestionId, setFileData, fileData }: IQuestionBox) {
  const { lang } = useContext(LangContext);
  const { isFormChanged, setIsFormChanged } = useCreateProjectStore();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
  const [rerender, setRerender] = useState(true);
  const isOpen = openQuestionId === id;
  const { setQuestionsLength } = useQuestionsStore();
  const [characterCount, setCharacterCount] = useState(existingText?.length || 0);
  const { currentTheme } = useTheme();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? '2px dashed #718096' : '1px solid #e2e8f0',
    opacity: isDragging ? 0.7 : 1,
    boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  };

  const handleDelete = () => {
    handleDeleteQuestion(id);
    setRerender(rerender);
  };

  const toggleQuestion = () => {
    setOpenQuestionId(isOpen ? null : id);
  };

  useLayoutEffect(() => {
    if (isActiveDrag) {
      setOpenQuestionId(null);
    }
  }, [isActiveDrag, setOpenQuestionId]);

  useEffect(() => {
    setQuestionsLength(questions?.length);
  }, [questions?.length]);

  useEffect(() => {
    if (!fileData[id]) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        [id]: {
          id: '',
          file: undefined,
        },
      }));
    }
  }, [id, fileData, setFileData]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-col w-full gap-5 bg-white border rounded-md shadow h-fit text-dark-text"
    >
      <button type="button" className="flex items-center justify-between w-full p-6" onClick={toggleQuestion}>
        {/* question title */}
        <h3 className="flex items-center w-[83%] font-medium whitespace-nowrap">
          {lang.get('msg.question')}
          {' '}
          {index + 1}
          {questions?.[index]?.question !== ''
            && (
              <span>:</span>
            )}
          {questions?.[index]?.question !== ''
            && (
              <span className="w-full truncate text-start ml-1.5">
                {questions?.[index]?.question}
              </span>
            )}
        </h3>
        <div className="flex items-center gap-4">
          {/* probing questions number */}
          <p className="mx-1 text-sm">
            {questions?.[index]?.follow_up_question}
          </p>
          <span>
            <IconChevronDown
              size={20}
              className={`transition-all ease-in-out ${isOpen && 'rotate-180'}`}
            />
          </span>
          {/* drag item */}
          {(!isUserViewer && !isProjectCompleted && !isProjectPublished && !isFormChanged) && (
            <span
              className={`${!isOpen ? 'flex' : 'hidden'}`}
              {...attributes}
              {...listeners}
            >
              <IconGripVertical size={24} className="relative z-50 text-light-gray cursor-grabbing" />
            </span>
          )}
        </div>
      </button>
      <div className={cn('flex flex-col gap-5 p-6 pt-0', openQuestionId !== id && 'hidden')}>
        <RequiredLabel title="question" tooltip={lang.get('msg.questionInfo')}>
          <Textarea
            {...register(`interview_questions.${index}.question`)}
            onChange={(e) => { setCharacterCount(e.target.value.length); setIsFormChanged(true); }}
            maxLength={700}
            disabled={isUserViewer || isProjectPublished || isProjectCompleted}
            placeholder={lang.get('msg.questionPlaceholder')}
            className="h-24"
          />
          <p className="text-sm text-gray-500">
            {characterCount}
            {' '}
            / 700 characters
          </p>
        </RequiredLabel>

        <div className="flex w-full gap-8 md700:flex-col">
          <div className="flex flex-col w-full gap-6 md700:w-full">
            {/* follow up question */}
            <RequiredLabel
              icon={(
                <SparklesIcon
                  className="p-1 rounded-md size-6"
                  style={{ backgroundColor: currentTheme?.primary,
                    color: getTextColor(currentTheme?.primary || ''),
                  }}
                />
)}
              title="aiProbingQuestions"
              tooltip={lang.get('msg.probingQuestionInfo')}
            >
              <Controller
                name={`interview_questions.${index}.follow_up_question`}
                control={control}
                render={({ field }) => (
                  <div className="relative z-50 w-full">
                    <Select
                      value={probingQuestions.find((option) => option.value === field?.value)}
                      onChange={(value) => { field.onChange(value?.value); setIsFormChanged(true); }}
                      options={probingQuestions}
                      isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                      placeholder={field?.value}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                          color: state.isFocused ? getTextColor(currentTheme?.primary as string) : '#000000',
                          ':active': {
                            backgroundColor: currentTheme?.primary,
                          },
                        }),
                      }}
                    />
                  </div>
                )}
              />
            </RequiredLabel>
            {/* uploading media */}
            <QuestionMediaUploading
              id={id}
              index={index}
              setFileData={setFileData}
              isProjectPublished={isProjectPublished}
              isProjectCompleted={isProjectCompleted}
              singleProjectData={singleProjectData}
            />
          </div>
        </div>
        {questions?.length > 1
          && (
            <Button
              disabled={isUserViewer || isProjectPublished || isProjectCompleted}
              variant="destructive"
              type="button"
              onClick={handleDelete}
              className="self-end"
            >
              {lang.get('msg.deleteQuestion')}
            </Button>
          )}
      </div>
    </div>
  );
}

export default QuestionBox;
