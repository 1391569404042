import { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown } from 'lucide-react';
import { format } from 'date-fns';
import { Button } from '@/components/ui/button';
import ProjectBrandingTableActions from './ProjectBrandingTableActions';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';

export interface ITelletsTableProps {
  _id: string;
  name: string,
  createdAt: string,
}

export const ProjectBrandingColumns = (handleOpenEditModal: (id: string) => void) => {
  const { isUserAdmin, isUserOwner } = useOrganizationRoles();

  const columns: ColumnDef<ITelletsTableProps>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-3">
          <button
            type="button"
            onClick={() => handleOpenEditModal(row?.original?._id)}
            className="transition-all ease-in-out hover:text-primary hover:underline"
          >
            {row?.getValue('name')}
          </button>
        </div>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Created at
          <ArrowUpDown className="w-4 h-4 ml-2" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex items-center gap-3">
          {format(row?.getValue('createdAt'), 'dd/MM/yyyy - HH:mm:ss')}
        </div>
      ),
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => (
        <ProjectBrandingTableActions
          row={row}
          handleOpenEditModal={handleOpenEditModal}
          isUserAdmin={isUserAdmin}
          isUserOwner={isUserOwner}
        />
      ),
    },
  ];
  return columns;
};
