export const listItems = [
  {
    id: 1,
    text: 'ask16500QuestionsPerYear',
  },
  {
    id: 2,
    text: 'theUseOfTemplates',
  },
  {
    id: 3,
    text: 'aiAnalysis',
  },
  {
    id: 4,
    text: 'structuredQuantitiveInsights',
  },
];

export const planRowsAndCols = [
  {
    id: 1,
    title: 'standardSetUpTemplate',
    data: [{
      isChecked: false,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
  {
    id: 2,
    title: 'summaryPerInterviewAndQuestion',
    data: [{
      isChecked: true,
      bgDiff: true,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
  {
    id: 3,
    title: 'tracking',
    data: [{
      isChecked: false,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
  {
    id: 4,
    title: 'aiSearch',
    data: [{
      isChecked: true,
      bgDiff: true,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
  {
    id: 5,
    title: 'supportingVideo',
    data: [{
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
  {
    id: 6,
    title: 'customBranding',
    data: [{
      isChecked: false,
      bgDiff: true,
    },
    {
      isChecked: false,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    {
      isChecked: true,
      bgDiff: false,
    },
    ],
  },
];
