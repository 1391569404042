import { ColumnDef } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconKeyboard, IconVideo, IconVolume } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import UserFilledIcon from '@/components/icons/UserFilledIcon';
import TooltipWrapper from '@/components/tooltip/TooltipWrapper';
import replaceUnderscoreWithSpace from '@/utils/transformUnderscoreToSpace';
import useTranslation from '@/hooks/useTranslation';

// eslint-disable-next-line import/prefer-default-export
export const TranscriptsColumns = (metadataKeysArray: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentTheme } = useTheme();
  const { lang } = useTranslation();

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const columns: ColumnDef<AllTranscriptsProps>[] = [
    {
      accessorKey: 'participants',
      header: () => (
        <Button
          variant="ghost"
          className="pl-0"
        // onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.participant')}
        </Button>
      ),
      cell: ({ row }) => (
        <button type='button' className="flex items-center h-full gap-3 group whitespace-nowrap"
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
        >
          <div
            className="flex items-center gap-3 font-semibold transition-all ease-in-out group-hover:underline"
          >
            <div
              style={{ color: currentTheme?.primary }}
            >
              <UserFilledIcon className="size-7" />
            </div>
            <p>
              {lang.get('msg.participant')}
              {' '}
              {row?.original?.respondent_index}
            </p>
            <div className="flex items-center ml-2 gap-1.5 text-black/80 whitespace-nowrap">
              {row?.original?.messages?.some((item) => item?.text !== '' && item?.role === 'user') && <IconKeyboard size={24} />}
              {row?.original?.messages.some((message) => message.data?.some((dataItem: File) => dataItem.type === 'audio') && message.role === 'user') && <IconVolume size={24} />}
              {row?.original?.messages.some((message) => message.data?.some((dataItem: File) => dataItem.type === 'video') && message.role === 'user') && <IconVideo size={24} />}
            </div>
          </div>
        </button>
      ),
    },
    {
      accessorKey: '_id',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.participant')}
          {' '}
          ID
        </Button>
      ),
      cell: ({ row }) => (
        <button type='button'
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
          className="whitespace-nowrap">
          <p>
            {row?.original?._id}
          </p>
        </button>
      ),
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.startTime')}
        </Button>
      ),
      cell: ({ row }) => (
        <button type='button'
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
          className="whitespace-nowrap">
          <p>
            {row?.getValue('createdAt')}
          </p>
        </button>
      ),
    },
    {
      accessorKey: 'duration',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.duration')}
        </Button>
      ),
      cell: ({ row }) => (
        <button type='button'
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
          className="whitespace-nowrap">
          <p>
            {row?.getValue('duration') !== 'NaN:NaN:NaN' ? row?.getValue('duration') : '/'}
          </p>
        </button>
      ),
    },
    {
      accessorKey: 'digest.summary',
      header: ({ column }) => (
        <Button
          variant="ghost"
          className="pl-0"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {lang.get('msg.summary')}
        </Button>
      ),
      cell: ({ row }) => (
        <TooltipWrapper text={row?.original?.digest?.summary || '/'} className="max-w-[700px] text-xs z-[9999999]">
          <p className="w-[500px] truncate">
            {row?.original?.digest?.summary || '/'}
          </p>
        </TooltipWrapper>
      ),
    },
  ];

  if (metadataKeysArray.length) {
    metadataKeysArray.forEach((key: string) => {
      columns.push({
        accessorKey: `metadata.${key}`,
        header: ({ column }) => (
          <Button
            variant="ghost"
            className="pl-0 capitalize"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            {replaceUnderscoreWithSpace(key)}
          </Button>
        ),
        cell: ({ row }) => (
          <button type='button'
            onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
            className="flex items-center justify-start w-full h-full whitespace-nowrap">
            {(row.original.metadata?.[key] || '/')}
          </button>
        ),
      });
    });
  }

  return columns;
};
