import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import { useGetUserData } from '@/reactQuery/get';
import Routes from '@/router/routes';
import OrganizationDropDown from './OrganizationDropDown';
import ProjectNavbar from './ProjectNavbar';
import UserIconDropDown from './UserIconDropDown';
import useTranslation from '@/hooks/useTranslation';
import { ECompanyThemeName } from '@/enums';

function Navbar() {
  const location = useLocation();
  const { data: user } = useGetUserData();
  const { organization_id } = useParams();
  const { currentTheme, themeName } = useTheme();
  const { lang } = useTranslation();

  const { data: orgData } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get/${organization_id}`),
    queryKey: ['current_organization_data', { organization_id }],
    select: (data) => data.data,
  });

  const isSubscribed = orgData?.subscription?.subscribed;

  if (location.pathname.includes(Routes.project_design.path)
    || location.pathname.includes(Routes.project_results.path)
    || location.pathname.includes(Routes.project_share.path)) return <ProjectNavbar user={user} orgData={orgData} />;

  return (
    <nav
      style={{ backgroundColor: currentTheme?.background }}
      className="fixed left-0 right-0 flex items-center justify-between max-w-full px-[2%] py-2 transition-all ease-in border z-60"
    >
      <OrganizationDropDown currentOrganization={organization_id} orgData={orgData} />
      <div className="flex items-center gap-4">
        {!isSubscribed && themeName === ECompanyThemeName.DEFAULT && (
        <Link
          className="px-4 py-1.5 text-sm font-semibold text-white rounded-md bg-chat-primary hover:scale-[1.02] transition-all ease-in-out hover:bg-[#ed638a]"
          to={`${Routes.organization.path}/${organization_id}${Routes.choose_your_plan.path}`}
        >
          {lang.get('msg.upgrade')}
        </Link>
        )}
        {/* user profile */}
        <button type="button" aria-label="user profile">
          <UserIconDropDown user={user} />
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
