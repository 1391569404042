import { create } from 'zustand';

type State = {
  isFormChanged: boolean;
  isMediaChanged: boolean;
  isMediaUploading: boolean;
  reorderChanged: boolean;
};

type Action = {
  setIsFormChanged: (change: boolean) => void;
  setIsMediaChanged: (change: boolean) => void;
  setIsMediaUploading: (uploading: boolean) => void;
  setReorderChanged: (uploading: boolean) => void;
};

const useCreateProjectStore = create<State & Action>((set) => ({
  isFormChanged: false,
  setIsFormChanged: (change) => set({ isFormChanged: change }),

  isMediaChanged: false,
  setIsMediaChanged: (change) => set({ isMediaChanged: change }),

  isMediaUploading: false,
  setIsMediaUploading: (uploading) => set({ isMediaUploading: uploading }),

  reorderChanged: false,
  setReorderChanged: (reorder) => set({ reorderChanged: reorder }),
}));

export default useCreateProjectStore;
