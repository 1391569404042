import useTheme from 'chat-ui/src/hooks/useTheme';
import TelletLogo from '@/assets/logo/tellet-logo-main.svg';
import EmpathyLogo from '@/assets/logo/empathy-logo-black.svg';
import Image from '../Image';
import { ECompanyThemeName } from '@/enums';

const themeLogosList = {
  [ECompanyThemeName.DEFAULT]: <Image src={TelletLogo} alt="logo" className="h-full m-auto w-36 animate-pulse" />,
  [ECompanyThemeName.EMPATHY]: <Image src={EmpathyLogo} alt="logo" className="h-full m-auto w-52 animate-pulse" />,
};

function FullPageLoader() {
  const { themeName } = useTheme();

  return (
    <div
      className="fixed inset-0 z-[99999] flex items-center justify-center w-full h-full text-center bg-white"
    >
      <div className="flex flex-col items-center justify-center">
        {themeLogosList[themeName]}
      </div>
    </div>
  );
}

export default FullPageLoader;
