import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import formatDate from '../../../lib/dateFormat';
import { cn } from '../../../lib/utils';
import MessageStatus from '../MessageStatus';
import MessageFactory from '../MessageFactory';
import ThreeDotLoader from '../../Loader/ThreeDotLoader';
import useChatStore from '../../../store';
import DOMPurify from "isomorphic-dompurify";
import getTextColor from '../../../hooks/getTextColorContrast';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

function TextMessage({
  text,
  data,
  created_at,
  seen,
  received,
  role,
  loading,
  hasError,
  isTestChat,
  isLastMessage,
}: TMessageData) {
  const { setIsLastMessageRerendered } = useChatStore((state) => state);
  const isUser = role === 'user';
  const date = formatDate(created_at, 'DD.MMM');
  const time = formatDate(created_at, 'hh:mm a');
  const { organization_id, project_id } = useParams();

  const formattedText = text?.replace(/\n/g, "<br>");
  const sanitizedFormattedText = DOMPurify.sanitize(formattedText || '');
  const [displayResponse, setDisplayResponse] = useState<string>('');
  const sanitizedDisplayResponse = DOMPurify.sanitize(displayResponse || '');

  // State to track which messages have had their images displayed
  const [shownImages, setShownImages] = useState<Set<string>>(new Set());
  const [isLastMessageDisplayed, setIsLastMessageDisplayed] = useState(false);

  const projectId = !!organization_id ? `/${project_id}` : window.location.pathname;

  const { data: themeData } = useQuery({
    queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get${projectId}/theme`),
    queryKey: ['chat_themes_branding', { projectId }],
    enabled: !!projectId,
    select: (data) => data?.data,
  });

  // Update shownImages state when data changes, displaying images immediately if they exist
  useEffect(() => {
    // Store file_id of images that have been shown
    if (data && data.length > 0) {
      setShownImages(new Set(data.map(item => item.file_id).filter((id): id is string => id !== undefined)));
    }
  }, [data]);

  // Display the message text chunk by chunk with a slight delay for animation effect
  useEffect(() => {
    if (!formattedText?.length || !isLastMessage) {
      return;
    }

    let i = 0; // Index to track current text display
    let j = 0; // Counter for triggering rerender after chunks
    const stringResponse = formattedText;
    const chunkSize = 35; // How many characters are displayed before triggering a rerender

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i + 1)); // Incrementally display text

      i++;
      j++;

      if (j === chunkSize) {
        setIsLastMessageRerendered(String(i)); // Trigger rerender every chunkSize characters
        j = 0;
      }

      if (i >= stringResponse.length) { // Stop when the whole message has been displayed
        clearInterval(intervalId); // Clear the interval once the full message is shown
        setIsLastMessageDisplayed(true); // Mark that the last message has been fully displayed
        if (j !== i) {
          setIsLastMessageRerendered(String(i)); // Final rerender after the message is fully displayed
        }
      }
    }, 10); // Update every 10ms for a typing effect

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [formattedText, isLastMessage]);

  // Function to render messages (both user and non-user) conditionally
  const renderMessages = () => {
    return data?.map((item, index) => {
      // 1. If image is displayed from the backend  - item.file_id
      // 2. If image is displayed from the frontend - item.url (Uploaded by user and displayed for the first time)
      const fileId = item.file_id || item.url;
      // Determine if the message should be rendered:
      // 1. If the current user is the sender (isUser is true).
      // 2. If there's a fileId and the file has been shown (fileId exists and is present in shownImages).
      const shouldRender = isUser || (fileId && shownImages.has(fileId));

      // If the message should be rendered, return the message element
      if (shouldRender) {
        return (
          <div className='min-h-[22px]' key={index}>
            {/* Render the message using MessageFactory, passing the item and isUser flag */}
            {MessageFactory({ ...item, isUser })}
          </div>
        );
      }
      // If the message shouldn't be rendered, return null
      return null;
    });
  };

  return (
    <div
      className={cn(
        'flex w-full self-end gap-3 md700:gap-1.5 max-w-[85%] min450:max-w-full transition-all',
        isUser ? 'self-end' : 'self-start',
      )}
    >
      <div className={cn('flex flex-col w-full', isUser && 'items-end w-full')}>
        <div
          style={{
            backgroundColor: isUser ? (themeData?.['chat-primary'] || '#FF6F98') : (themeData?.['chat-secondary'] || "#FFE8ED"),
            color: getTextColor((isUser ? (themeData?.['chat-primary'] || '#FF6F98') : (themeData?.['chat-secondary'] || "#FFE8ED")) || '#FFFFFF')
          }}
          className={cn(
            'w-fit px-3 py-2.5 font-medium md700:ml-0 text-[15px] shadow-md max-w-[85%] min450:max-w-[95%] relative',
            hasError && '!bg-red-400 border !text-white',
            isUser
              ? isTestChat ? "md600:ml-0 rounded-l-xl rounded-t-xl" : 'md600:ml-0 rounded-l-xl rounded-t-xl'
              : isTestChat ? 'md600:ml-0 rounded-br-xl rounded-t-xl' : 'md600:ml-0 rounded-br-xl rounded-t-xl',
          )}
        >
          {/* Show loading animation if the message is still being sent */}
          {loading && (
            <div className="px-4 h-[22px] flex items-center">
              <ThreeDotLoader />
            </div>
          )}

          {/* Render message text, sanitize HTML and set dangerous inner HTML if it's the last message */}
          {isUser
            ? <p>{sanitizedFormattedText}</p>
            : isLastMessage && !loading ? (
              <p className='[&_a]:underline min-h-[22px] [&_a]:text-blue-400'
                dangerouslySetInnerHTML={{ __html: sanitizedDisplayResponse }} />
            ) : (
              <p className='[&_a]:underline [&_a]:text-blue-400'
                dangerouslySetInnerHTML={{ __html: sanitizedFormattedText }} />
            )
          }

          {/* Conditional rendering for messages based on user and message state */}
          <div>
            {(!isUser && (isLastMessageDisplayed || !isLastMessage)) || isUser ? renderMessages() : null}
          </div>

        </div>

        {/* Display timestamp and message status (seen/received) */}
        <div className={cn('flex items-center', isUser ? 'justify-end' : 'justify-start')}>
          <span className="mt-2 ml-1 text-[10px] text-gray-500">
            <span className="mr-1">{date}</span>
            {time}
          </span>
          {isUser && <MessageStatus seen={seen} received={received} />}
        </div>
      </div>
    </div>
  );
}

export default TextMessage;
