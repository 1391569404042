import useTranslation from '@/hooks/useTranslation';

interface IPageTitleProps {
  title: string;
}

function PageTitle({ title }: IPageTitleProps) {
  const { lang } = useTranslation();

  return <h1 className="!mb-2 text-xl font-semibold epilogue-font">{lang.get(`msg.${title}`)}</h1>;
}

export default PageTitle;
