import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { IconChevronLeft, IconChevronRight, IconLoader2, IconSearch, IconTrash } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import dayjs from 'dayjs';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import { useMutateAiSearchAskQuestion, useMutateDeleteAskedQuestion } from '@/reactQuery/post';
import AISearchConversationTable from './ai_search_content/AISearchConversationTable';
import TooltipWrapper from '@/components/tooltip/TooltipWrapper';

interface ISearchData {
  _id: string;
  question: string;
  created_at: string;
}

function AISearch() {
  const { lang } = useTranslation();
  const { project_id } = useParams();
  const [inputQuestion, setInputQuestion] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const questions_pagination = searchParams.get('ai_search_question_page');
  const question_params_id = searchParams.get('ai_search_question');
  const filter_id = searchParams.get('filter_id');
  const conversation_selected = searchParams.get('selected_transcript');
  const [currentPage, setCurrentPage] = useState<number>(Number(questions_pagination || 1));
  const { currentTheme } = useTheme();

  const { data: searchData } = useQuery({
    queryFn: () => axiosInstanceAnalyze.get(`/analyzer/results/${project_id}/aisearch${filter_id ? `?filter_id=${filter_id}` : ''}`),
    queryKey: ['aisearch_data', { filter_id }],
    select: (data) => data?.data,
  });

  const { mutateAsync: mutateAsyncSearchAskQuestion, status } = useMutateAiSearchAskQuestion(filter_id, conversation_selected, searchData);
  const { mutateAsync: mutateAsyncDeleteQuestion } = useMutateDeleteAskedQuestion();

  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const paginate = (pageNumber: number) => {
    addQueryParams({ ai_search_question_page: String(pageNumber) });
    setCurrentPage(pageNumber);
  };

  const handleDeleteAskedQuestion = async (search_id: string) => {
    try {
      await mutateAsyncDeleteQuestion({ project_id, search_id, filter_id: filter_id || null });
      toast({ description: lang.get('msg.questionSuccessfullyDeleted') });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const handleAskingQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (inputQuestion.trim() !== '') {
        await mutateAsyncSearchAskQuestion({ project_id, inputQuestion });
        setInputQuestion('');
      }
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const selectedQuestionAnswer = searchData?.searches?.filter((item: { _id: string }) => item._id === question_params_id);

  return (
    <div
      style={{ backgroundColor: currentTheme?.background }}
      className="flex flex-col w-full mx-auto"
    >
      {/* search field */}
      <form className="flex w-full" onSubmit={handleAskingQuestion}>
        <Input
          icon={<IconSearch size={22} />}
          className="rounded-r-none"
          placeholder={lang.get('msg.askYourQuestionHere')}
          value={inputQuestion || ''}
          onChange={(e) => setInputQuestion(e.target.value)}
          disabled={status === 'pending'}
        />
        <Button
          variant="default"
          className="rounded-l-none"
          type="submit"
          disabled={status === 'pending'}
        >
          {status === 'pending' && <IconLoader2 className="mr-2 animate-spin" size={18} />}
          {lang.get('msg.search')}
        </Button>
      </form>

      {/* history & responses */}
      {searchData?.searches?.length > 0 && (
        <div className="flex w-full gap-4 mt-7 md900:flex-col">
          {/* history */}
          <div className="flex flex-col w-full border divide-y rounded-md shadow h-fit max-w-96 md900:max-w-full">
            {searchData?.searches?.slice(indexOfFirstItem, indexOfLastItem).map((item: ISearchData) => (
              <TooltipWrapper text={item?.question} className="w-full max-w-96" key={item?._id}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  onClick={() => addQueryParams({ ai_search_question: item?._id, ai_search_question_page: String(currentPage) })}
                  style={{ backgroundColor: item?._id === question_params_id ? currentTheme?.['lighter-primary'] : '' }}
                  className={cn(
                    'relative flex flex-col text-start w-full gap-1 px-6 py-4 cursor-pointer group',
                  )}
                >
                  <p className="text-sm font-semibold line-clamp-2">{item?.question}</p>
                  <p className="text-xs font-medium">{dayjs(item?.created_at).format('DD/MM/YY')}</p>
                  {/* delete question form history */}
                  <button
                    type="button"
                    onClick={() => handleDeleteAskedQuestion(item?._id)}
                    className="absolute group-hover:flex hidden bg-red-500 text-white rounded-full p-1 right-2 bottom-2 hover:scale-[1.1] transition-all ease-in-out"
                  >
                    <IconTrash size={14} />
                  </button>
                </div>
              </TooltipWrapper>
            ))}

            {/* history pagination */}
            <div className="p-1">
              <div className="flex items-center justify-between w-full">
                <Button
                  variant="ghost"
                  className="cursor-pointer select-none"
                  onClick={() => {
                    if (currentPage > 1) {
                      paginate(currentPage - 1);
                    }
                  }}
                  disabled={currentPage === 1}
                >
                  <IconChevronLeft className="mr-1 pt-0.5" size={18} />
                  {lang.get('msg.previous')}
                </Button>

                <Button
                  variant="ghost"
                  type="button"
                  className="flex items-center cursor-pointer select-none"
                  onClick={() => {
                    if (
                      searchData?.searches
                      && searchData.searches.length >= 10
                      && indexOfLastItem < searchData.searches.length
                    ) {
                      paginate(currentPage + 1);
                    }
                  }}
                  disabled={
                    !searchData?.searches
                    || searchData.searches.length < 10
                    || indexOfLastItem >= searchData.searches.length
                  }
                >
                  {lang.get('msg.next')}
                  <IconChevronRight className="ml-1 pt-0.5" size={18} />
                </Button>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            {/* answer */}
            <div
              style={{ backgroundColor: currentTheme?.['lighter-primary'] }}
              className="relative flex flex-col w-full gap-4 p-6 rounded-md shadow"
            >
              <div className="flex justify-between w-full">
                <div className="flex flex-col w-full">
                  <p className="font-semibold">
                    {lang.get('msg.question')}
                    :
                  </p>
                  <p>{selectedQuestionAnswer?.[0]?.question}</p>
                </div>
                {/* delete question */}
                <button
                  onClick={() => handleDeleteAskedQuestion(selectedQuestionAnswer?.[0]?._id)}
                  type="button"
                  className="bg-red-500 hover:scale-[1.1] h-7 w-7 flex items-center justify-center transition-all ease-in-out text-white p-1 rounded-full"
                >
                  <IconTrash size={16} />
                </button>
              </div>
              <div className="flex items-center justify-between w-full">
                <p className="font-semibold">
                  {lang.get('msg.answer')}
                  :
                </p>
              </div>
              <div className="text-sm font-medium">
                <ReactMarkdown>
                  {selectedQuestionAnswer?.[0]?.answer}
                </ReactMarkdown>
              </div>
            </div>

            {/* conversation messages */}
            <AISearchConversationTable snippets={selectedQuestionAnswer?.[0]?.snippets || []} />
          </div>
        </div>
      )}
    </div>
  );
}

export default AISearch;
