import useThemeStore from '../ThemeStore';
import { Themes, ThemeProps, ThemeName } from '../theme/themes';

type UseThemeReturn = {
  themeName: ThemeName;
  allThemes: Themes;
  currentTheme: ThemeProps | undefined;
  changeTheme: (id: ThemeName) => void;
};

const useTheme = (): UseThemeReturn => {
  const themeName = useThemeStore((state) => state.theme);
  const allThemes = useThemeStore((state) => state.allThemes);

  const currentTheme = allThemes[themeName];

  const changeTheme = useThemeStore((state) => state.changeTheme);

  return { themeName, allThemes, currentTheme, changeTheme };
};

export default useTheme;
