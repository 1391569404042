import { createBrowserRouter } from 'react-router-dom';
import Routes from './routes';
import App from '@/App';
import Login from '../pages/auth/Login';
import SignUp from '../pages/auth/SignUp';
import ForgotPassword from '../pages/auth/ForgotPassword';
import Error404Page from '../pages/error/Error404Page';
import AuthWrapper from '../layouts/AuthWrapper';
import Unauthorized from '../pages/error/Unauthorized';
import VerificationCheckYourEmail from '../pages/auth/CheckYourEmail';
import VerifyEmail from '../pages/auth/VerifyEmail';
import ResetPassword from '../pages/auth/ResetPassword';
import MyProfile from '../pages/MyProfile';
import AcceptAccountInvitation from '../pages/auth/AcceptAccountInvitation';
import AcceptOrganizationInvitation from '../pages/auth/AcceptOrganizationInvitation';
import MyWorkspace from '../pages/projects/MyWorkspace';
import ProjectCreate from '../pages/projects/single-project/ProjectPages/ProjectCreate';
import ProjectShare from '../pages/projects/single-project/ProjectPages/ProjectShare';
import ProjectResults from '../pages/projects/single-project/ProjectPages/ProjectResults';
import AuthOrganizationalSettings from '../layouts/AuthOrganizationalSettings';
import OrganizationInvitationExpired from '../pages/auth/OrganizationInvitationExpired';
import EmailVerificationExpired from '../pages/auth/EmailVerificationExpired';
import ThemeWrapperDashboard from '@/layouts/ThemeWrapperDashboard';
import ProjectsBranding from '@/pages/settings/ProjectsBranding';
import ChooseYourPlan from '@/pages/ChooseYourPlan';

const RouterWrapper = () => {
  const router = createBrowserRouter([
    {
      element: <ThemeWrapperDashboard />,
      children: [
        // -- PUBLIC ROUTES --
        {
          index: true,
          path: Routes.home.path,
          element: <App />,
        },
        {
          path: Routes.login.path,
          element: <Login />,
        },
        {
          path: Routes.sign_up.path,
          element: <SignUp />,
        },
        {
          path: Routes.forgot_password.path,
          element: <ForgotPassword />,
        },
        {
          path: Routes.reset_password.path,
          element: <ResetPassword />,
        },
        {
          path: Routes.unauthorized.path,
          element: <Unauthorized />,
        },
        {
          path: Routes.check_your_email.path,
          element: <VerificationCheckYourEmail />,
        },
        {
          path: Routes.email_verify.path,
          element: <VerifyEmail />,
        },
        {
          path: Routes.accept_account_invitation.path,
          element: <AcceptAccountInvitation />,
        },
        {
          path: Routes.accept_account_organization_invitation.path,
          element: <AcceptOrganizationInvitation />,
        },
        {
          path: Routes.organization_invitation_link_expired.path,
          element: <OrganizationInvitationExpired />,
        },
        {
          path: Routes.verification_email_link_expired.path,
          element: <EmailVerificationExpired />,
        },

        // -- PROTECTED ROUTES --
        {
          element: <AuthWrapper />,
          children: [
            {
              path: `${Routes.organization.path}/:organization_id${Routes.manage_account.path}`,
              element: <MyProfile />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.organizational_settings.path}`,
              element: <AuthOrganizationalSettings />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.projects_rebranding.path}`,
              element: <ProjectsBranding />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.workspace.path}/:workspace_id`,
              element: <MyWorkspace />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.workspace.path}/:workspace_id${Routes.project_design.path}/:project_id`,
              element: <ProjectCreate />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.workspace.path}/:workspace_id${Routes.project_share.path}/:project_id`,
              element: <ProjectShare />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.workspace.path}/:workspace_id${Routes.project_results.path}/:project_id`,
              element: <ProjectResults />,
            },
            {
              path: `${Routes.organization.path}/:organization_id${Routes.choose_your_plan.path}`,
              element: <ChooseYourPlan />,
            },
          ],
        },
        // -- CATCH ALL ERRORS --
        {
          path: Routes.error.path,
          element: <Error404Page />,
        },
        {
          path: '*',
          element: <Error404Page />,
        },
      ],
    },
  ]);

  return router;
};

export default RouterWrapper;
