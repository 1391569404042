import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import Snippets from './components/Snippets';

interface IInstructionsProps {
  workspaceID: string | undefined;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: any;
}

function Context({ workspaceID, isProjectPublished, isProjectCompleted, control, watch, register }: IInstructionsProps) {
  const { isUserViewer } = useWorkspaceRoles(workspaceID);

  return (
    <div className="w-full h-full pl-1">
      <div className="flex flex-col w-full gap-5 pb-10">
        {/* -- snippets -- */}
        <Snippets
          control={control}
          watch={watch}
          register={register}
          isUserViewer={isUserViewer}
          isProjectPublished={isProjectPublished}
          isProjectCompleted={isProjectCompleted}
        />
      </div>
    </div>
  );
}

export default Context;
