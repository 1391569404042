import { useState } from 'react';
import { Control, Controller, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import axiosInstance from '@/axios/axiosInstance';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { ECompanyThemeName } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import languagesList from '@/lang/languages/languagesList';
import InstructionsSkelet from './components/InstructionsSkelet';
import toneOfVoiceList from './components/toneOfVoiceList';
import useCreateProjectStore from '@/store/CreateProjectStore';
import getTextColor from '@/utils/getTextColorContrast';

interface IInstructionsProps {
  singleProjectData: IProjectProps;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any;
  isProjectDataLoading: boolean;
}

function Instructions({ isProjectPublished, isProjectCompleted, singleProjectData, register, control, setValue, errors, isProjectDataLoading }: IInstructionsProps) {
  const { lang } = useTranslation();
  const { organization_id, workspace_id } = useParams();
  const [interviewGoalCharacters, setInterviewGoalCharacters] = useState(singleProjectData?.interview_goal || '');
  const [interviewAudienceCharacters, setInterviewAudienceCharacters] = useState(singleProjectData?.interview_audience || '');
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { themeName, currentTheme } = useTheme();
  const { setIsFormChanged } = useCreateProjectStore();

  const { data: allBrandingsList, isLoading: isAllBrandingsListLoading } = useQuery({
    queryFn: () => axiosInstance.get('/themes/organization/all'),
    queryKey: ['all_brandings_data', { organization_id }],
    enabled: !!organization_id,
    select: (data) => data?.data?.map((branding: { _id: string, name: string }) => ({
      label: branding.name,
      value: branding._id,
    })),
  });

  return (
    <div className="w-full h-full pl-1">
      {!isProjectDataLoading && !isAllBrandingsListLoading
        ? (
          <div className="flex flex-col w-full gap-5 pb-10 pr-1">
            {/* -- interviewer name -- */}
            <RequiredLabel title="interviewerName" tooltip={lang.get('msg.interviewerNameInfo')}>
              <Input
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interviewer_name')}
                type="text"
                placeholder={lang.get('msg.interviewerNamePlaceholder')}
                onChange={() => setIsFormChanged(true)}
              />
            </RequiredLabel>

            {/* -- company name -- */}
            <RequiredLabel title="companyName" tooltip={lang.get('msg.companyNameInfo')}>
              <Input
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('company_name')}
                type="text"
                placeholder={lang.get('msg.companyNamePlaceholder')}
                onChange={() => setIsFormChanged(true)}
              />
            </RequiredLabel>

            {/* -- interview goal -- */}
            <RequiredLabel title="myResearchObjective" tooltip={lang.get('msg.myResearchObjectiveInfo')}>
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interview_goal')}
                maxLength={700}
                className="h-28 rounded-xl"
                placeholder={lang.get('msg.myResearchObjectivePlaceholder')}
                onChange={(e) => { setInterviewGoalCharacters(e.target.value); setIsFormChanged(true); }}
              />
              <p className="text-xs text-right text-light-dark">
                {700 - interviewGoalCharacters.length}
                {' '}
                {lang.get('msg.charactersLeft')}
              </p>
            </RequiredLabel>

            {/* -- interview audience -- */}
            <RequiredLabel
              title="interviewAudience"
              tooltip={
                themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.interviewAudienceInfo_empathy')
                  : lang.get('msg.interviewAudienceInfo')
              }
            >
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register('interview_audience')}
                onChange={(e) => { setInterviewAudienceCharacters(e.target.value); setIsFormChanged(true); }}
                className="h-28 rounded-xl"
                maxLength={100}
                placeholder={lang.get('msg.interviewAudiencePlaceholder')}
              />
              <p className="text-xs text-right text-light-dark">
                {100 - interviewAudienceCharacters.length}
                {' '}
                {lang.get('msg.charactersLeft')}
              </p>
            </RequiredLabel>

            {/* language */}
            <RequiredLabel title="primaryLanguage" tooltip={lang.get('msg.primaryLanguageInfo')}>
              <Controller
                name="language"
                control={control}
                render={({ field }) => (
                  <Select
                    value={languagesList.language.find((option) => option.label === field?.value)}
                    onChange={(value) => { field.onChange(value?.label); setIsFormChanged(true); }}
                    options={languagesList.language}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    placeholder={lang.get('msg.selectInterviewLanguage')}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                        color: state.isFocused ? getTextColor(currentTheme?.primary as string) : '#000000',
                        ':active': {
                          backgroundColor: currentTheme?.primary,
                        },
                      }),
                    }}
                  />
                )}
              />
            </RequiredLabel>

            {/* allow language changing */}
            <RequiredLabel title="allowParticipantsToChooseTheLanguage" tooltip={lang.get('msg.allowToChooseLanguageInfo')}>
              <Controller
                name="allow_language_selection"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col gap-1.5 mb-2">
                    <div className="flex items-center gap-1.5">
                      <input
                        type="radio"
                        id="allowLanguageYes"
                        value="true"
                        checked={field.value === true}
                        disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                        onChange={() => {
                          setValue('allow_language_selection', true);
                          setIsFormChanged(true);
                        }}
                      />
                      <label className="text-sm" htmlFor="allowLanguageYes">{lang.get('msg.yes')}</label>
                    </div>
                    <div className="flex items-center gap-1.5">
                      <input
                        type="radio"
                        id="allowLanguageNo"
                        value="false"
                        checked={field.value === false}
                        disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                        onChange={() => {
                          setValue('allow_language_selection', false);
                          setIsFormChanged(true);
                        }}
                      />
                      <label className="text-sm" htmlFor="allowLanguageNo">{lang.get('msg.no')}</label>
                    </div>
                  </div>
                )}
              />
            </RequiredLabel>

            {/* analysis language */}
            <RequiredLabel title="analysisLanguage" tooltip={lang.get('msg.analysisLanguageInfo')}>
              <Controller
                name="analysis_language"
                control={control}
                render={({ field }) => (
                  <Select
                    value={languagesList.language.find((option) => option.label === field?.value)}
                    onChange={(value) => { field.onChange(value?.label); setIsFormChanged(true); }}
                    options={languagesList.language}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    placeholder={lang.get('msg.selectAnalysisLanguage')}
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                        color: state.isFocused ? getTextColor(currentTheme?.primary as string) : '#000000',
                        ':active': {
                          backgroundColor: currentTheme?.primary,
                        },
                      }),
                    }}
                  />
                )}
              />
            </RequiredLabel>

            {/* privacy policy */}
            <RequiredLabel title="privacyPolicy" tooltip={lang.get('msg.privacyPolicyInfo')}>
              <div className="relative w-full">
                <Input
                  disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                  {...register('privacy_policy')}
                  type="text"
                  placeholder={lang.get('msg.insertYourPrivacyPolicyHere')}
                  hasError={!!errors?.privacy_policy}
                  onChange={() => setIsFormChanged(true)}
                />
                {!!errors?.privacy_policy && (
                  <p className="left-0 text-xs font-medium text-red-600 -bottom-5">
                    {errors?.privacy_policy?.message}
                  </p>
                )}
              </div>
            </RequiredLabel>

            {/* -- tone -- */}
            <RequiredLabel title="toneOfVoice" tooltip={lang.get('msg.toneOfVoiceInfo')}>
              <Controller
                name="tone_of_voice"
                control={control}
                render={({ field }) => (
                  <Select
                    value={toneOfVoiceList.find((option) => option.value === field?.value)}
                    onChange={(value) => { field.onChange(value?.value); setIsFormChanged(true); }}
                    options={toneOfVoiceList.map((option) => ({
                      ...option,
                      label: lang.get(`msg.${option.label}`),
                    }))}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    placeholder={lang.get('msg.selectToneOfVoice')}
                    menuPlacement="top"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                        color: state.isFocused ? getTextColor(currentTheme?.primary as string) : '#000000',
                        ':active': {
                          backgroundColor: currentTheme?.primary,
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        textTransform: 'capitalize',
                      }),
                    }}
                  />
                )}
              />
            </RequiredLabel>

            {/* branding */}
            <RequiredLabel notRequired title="projectBranding" tooltip={lang.get('msg.projectBranding')}>
              <Controller
                name="theme_id"
                control={control}
                render={({ field }) => (
                  <Select
                    value={allBrandingsList.find((option: { value: string }) => option.value === field?.value)}
                    onChange={(value) => { field.onChange(value?.value); setIsFormChanged(true); }}
                    options={allBrandingsList}
                    isDisabled={isUserViewer || isProjectPublished || isProjectCompleted}
                    placeholder={lang.get('msg.projectBranding')}
                    menuPlacement="top"
                    styles={{
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                        color: state.isFocused ? getTextColor(currentTheme?.primary as string) : '#000000',
                        ':active': {
                          backgroundColor: currentTheme?.primary,
                        },
                      }),
                    }}
                  />
                )}
              />
            </RequiredLabel>
          </div>
        ) : (
          <InstructionsSkelet />
        )}
    </div>
  );
}

export default Instructions;
