import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { useMutateRenameTellet } from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import { Input } from '../ui/input';
import { LangContext } from '@/context/LangProvider';
import { Button } from '../ui/button';
import { ECompanyThemeName, EStatusCode } from '@/enums';

interface IRenameProps {
  projectName: string;
}

interface IModalProps {
  project_id: string;
}

const validationSchema = Yup.object().shape({
  projectName: Yup.string().required('New tellet name is required'),
});

function RenameTelletModal({ project_id }: IModalProps) {
  const { lang } = useContext(LangContext);
  const { themeName } = useTheme();

  const defaultValues = {
    projectName: '',
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema) });
  const [openModal, setOpenModal] = useState(false);

  const { mutateAsync } = useMutateRenameTellet();

  const onSubmit: SubmitHandler<IRenameProps> = async (data) => {
    try {
      await mutateAsync({ projectName: data?.projectName, project_id });
      reset();
      setOpenModal(false);
      toast({ description: lang.get('msg.projectSuccessfullyRenamed') });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.PROJECT_NAME_EXISTS) {
        toast({ description: `${lang.get('msg.projectWithSameNameAlreadyExists')}` });
      } else {
        toast({ description: `${lang.get('msg.errorPleaseTryAgain')}`, variant: 'destructive' });
      }
    }
  };

  return (
    <AlertDialog open={openModal}>
      <AlertDialogTrigger onClick={() => setOpenModal(true)} asChild>
        <p className="py-1 pl-2 text-sm cursor-pointer hover:bg-secondary-background">{lang.get('msg.rename')}</p>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {themeName === ECompanyThemeName.EMPATHY
                ? lang.get('msg.renameYourTellet_empathy') : lang.get('msg.renameYourTellet')}
            </AlertDialogTitle>
            <div className="flex flex-col pt-5 pb-12">
              <label className="relative flex flex-col w-full gap-1">
                <Input
                  {...register('projectName')}
                  placeholder={themeName === ECompanyThemeName.EMPATHY
                    ? lang.get('msg.renameYourTellet_empathy') : lang.get('msg.renameYourTellet')}
                  className={`w-full ${errors?.projectName?.message && 'border-red-600/50'}`}
                />
                <p
                  className={`text-xs md700:hidden font-medium text-red-600 opacity-0 absolute -bottom-5 ${errors?.projectName?.message && 'opacity-100'
                  }`}
                >
                  {errors?.projectName?.message}
                </p>
              </label>
            </div>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setOpenModal(false)}>{lang.get('msg.cancel')}</AlertDialogCancel>
            <Button
              variant="default"
              type="submit"
              disabled={isSubmitting}
            >
              {lang.get('msg.submit')}
            </Button>
          </AlertDialogFooter>
        </form>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default RenameTelletModal;
