import React from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Skeleton } from '../ui/skeleton';

interface IStepsData {
  data: IData[] | undefined,
  isLoading?: boolean;
}

interface IData {
  title: string;
  body: string
}

function DataWithSteps({ data, isLoading }: IStepsData) {
  const { currentTheme } = useTheme();
  return (
    !isLoading ? (
      <div className="flex flex-col w-full pl-1">
        {React.Children.toArray(data?.map((item: { title: string, body: string }, index: number) => (
          <div className="relative flex h-full gap-6 pb-5">
            <div className="flex flex-col h-full w-fit">
              <p className="flex items-center mt-0.5 justify-center w-5 h-5 text-xs font-bold rounded-md text-slate-600 bg-slate-200">
                {index + 1}
              </p>
              {index !== data.length - 1 && (
              <div className="absolute w-2 h-full border-l-2 top-5 left-[9px] border-slate-200"></div>
              )}
            </div>
            <div className="flex flex-col w-full gap-4">
              <p
                style={{ color: currentTheme?.primary }}
                className="font-semibold"
              >
                {item?.title}
              </p>
              <div className="text-sm font-medium">
                {item?.body}
              </div>
            </div>
          </div>
        )))}
      </div>
    ) : (
      <div className="flex flex-col w-full pl-1">
        {[1, 2, 3, 4]?.map((item, index) => (
          <div key={item} className="relative flex h-full gap-6 pb-5">
            <div className="flex flex-col h-full w-fit">
              <Skeleton className="w-5 h-5 rounded-md" />
              {index !== [1, 2, 3, 4].length - 1 && (
              <Skeleton className="absolute w-1 h-full border-l-2 top-5 left-[9px]" />
              )}
            </div>
            <div className="flex flex-col w-full gap-3">
              <Skeleton className="h-5 rounded-sm w-72" />
              <div className="flex flex-col gap-1 text-sm font-medium">
                <Skeleton className="w-full h-4 rounded-sm" />
                <Skeleton className="h-4 rounded-sm w-[90%]" />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  );
}

export default DataWithSteps;
