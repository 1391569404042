import { IconCheck } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { Children, useState } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Switch } from '@/components/ui/switch';
import { cn } from '@/lib/utils';
import useTranslation from '@/hooks/useTranslation';
import { listItems, planRowsAndCols } from '@/components/chooseYourPlan/chooseYourPlanLists';
import Routes from '@/router/routes';
import { ECompanyThemeName } from '@/enums';

function ChooseYourPlan() {
  const { lang } = useTranslation();
  const [isAnnually, setIsAnnually] = useState(true);
  const { themeName } = useTheme();
  const navigate = useNavigate();

  const handleSwitch = () => {
    setIsAnnually((prev) => !prev);
  };

  if (themeName !== ECompanyThemeName.DEFAULT) {
    navigate(Routes.error.path);
  }

  return (
    <main className="w-full h-full pt-5 pb-20">
      <div className="flex max-w-[1200px] mx-auto flex-col">
        <div className="flex items-center max1200:flex-col max1200:gap-14 justify-between md900:px-10 border-[3px] border-chat-primary rounded-[70px] px-24 py-20 bg-[#FFF1F6]">
          <div className="flex flex-col max1200:justify-center max1200:items-center max1200:text-center">
            <h1 className="text-[40px] font-bold mb-1">{lang.get('msg.chooseYourPlan')}</h1>
            <p className="text-lg font-medium max-w-[550px]">{lang.get('msg.designedToHelpBusinessesCollectMoreAndBetterDataWithAIinterviews')}</p>
            <div className="flex items-center gap-5 mt-10 md800:flex-col md800:w-full">
              <Link
                to="https://tellet.ai/demo"
                target="_blank"
                className="p-6 py-2.5 md800:w-full text-lg border-2 text-white border-chat-primary font-semibold rounded-full bg-chat-primary hover:scale-[1.02] transition-all ease-in-out hover:bg-[#ed638a]"
              >
                {lang.get('msg.bookACall')}
              </Link>
              <Link to="https://tellet.ai/demo" target="_blank" className="p-6 py-2.5 md800:w-full text-lg font-semibold border-2 border-chat-primary text-chat-primary hover:text-white rounded-full bg-transparent transition-all ease-in-out hover:bg-[#ed638a]">
                {lang.get('msg.contactUs')}
              </Link>
            </div>
          </div>
          <ul className="flex flex-col gap-6">
            {listItems?.map((item) => (
              <li key={item.id} className="flex items-center gap-4 text-lg font-medium whitespace-nowrap md700:whitespace-normal">
                <IconCheck size={25} className="p-1 text-white rounded-full bg-chat-primary min-w-[25px]" />
                {lang.get(`msg.${item?.text}`)}
              </li>
            ))}
          </ul>
        </div>

        {/* pricing section */}
        <div className="flex flex-col w-full max-w-[1200px] mx-auto h-[400px] mt-16">
          <div className="grid items-center w-full grid-cols-5">
            {/* 1 */}
            <div className="flex flex-col items-center justify-center h-full px-2">
              <div className="flex flex-col">
                <Switch className="data-[state=checked]:bg-chat-primary mb-3" checked={isAnnually} onCheckedChange={handleSwitch} />
                <p className="text-sm font-bold">{lang.get('msg.billedAnnually')}</p>
                <small className="font-medium">
                  {lang.get('msg.save')}
                  {' '}
                  20%
                </small>
              </div>
            </div>
            {/* 2 */}
            <div className="flex bg-[#FFF1F6] text-[#646464] flex-col items-center justify-start h-full  text-center py-14 px-2">
              <div className="flex flex-col">
                <p className="mb-1 font-bold">{lang.get('msg.perProject')}</p>
                <h3 className="text-[34px] max1200:text-2xl font-bold">&euro;950</h3>
                <p className="text-sm lowercase font-semibold leading-[18px]">
                  {lang.get('msg.minimum')}
                  <br />
                  {lang.get('msg.perProject')}
                </p>
              </div>
              <div className="flex flex-col mt-10">
                <h3 className="text-[34px] max1200:text-2xl font-bold">&euro;1</h3>
                <p className="text-sm font-semibold">
                  {lang.get('msg.perQuestion')}
                </p>
              </div>
            </div>
            {/* 3 */}
            <div className="flex flex-col items-center justify-between h-full px-2 pb-8 pt-14">
              <div className="flex flex-col items-center">
                <p className="font-bold">
                  {' '}
                  {lang.get('msg.starter')}
                </p>
                <h3 className="text-[34px] max1200:text-2xl font-bold">
                  &euro;
                  {isAnnually ? '9,750' : '11,700'}
                </h3>
              </div>
              <div className="flex flex-col mt-10 text-center">
                <h3 className="text-[34px] max1200:text-2xl font-bold mt-10">16,500</h3>
                <p className="text-sm font-semibold lowercase">{lang.get('msg.questions')}</p>
              </div>
              <Link to="https://tellet.ai/demo" target="_blank" className="px-8 py-3 mt-10 text-sm font-semibold text-center rounded-full max1200:px-4 text-chat-primary bg-light-freemium-pink">Choose plan</Link>
            </div>
            {/* 4 */}
            <div className="flex flex-col items-center justify-between max1200:h-[340px] h-[370px] pt-10 pb-5 text-center text-white bg-[#310125] rounded-2xl px-2">
              <div className="flex flex-col">
                <p className="font-bold">{lang.get('msg.premium')}</p>
                <h3 className="text-[34px] max1200:text-2xl font-bold">
                  &euro;
                  {isAnnually ? '24,750' : '29,000'}
                </h3>
              </div>
              <div className="flex flex-col mt-2">
                <h3 className="text-[34px] max1200:text-2xl font-bold mt-14">62,500</h3>
                <p className="text-sm font-semibold lowercase">{lang.get('msg.questions')}</p>
              </div>
              <Link to="https://tellet.ai/demo" target="_blank" className="px-8 py-3 mt-6 text-sm font-semibold text-center bg-white rounded-full max1200:px-4 text-chat text-dark-text">Choose plan</Link>
            </div>
            {/* 5 */}
            <div className="flex flex-col items-center justify-between h-full px-2 pb-8 text-center pt-14">
              <div className="flex flex-col">
                <p className="font-bold">{lang.get('msg.advanced')}</p>
                <h3 className="text-[34px] max1200:text-2xl font-bold">
                  &euro;
                  {isAnnually ? '49,750' : '59,400'}
                </h3>
              </div>
              <div className="flex flex-col">
                <h3 className="text-[34px] max1200:text-2xl font-bold mt-14">200,000</h3>
                <p className="text-sm font-semibold lowercase">{lang.get('msg.questions')}</p>
              </div>
              <Link to="https://tellet.ai/demo" target="_blank" className="px-8 py-3 mt-6 text-sm font-semibold text-center rounded-full max1200:px-4 text-chat-primary bg-light-freemium-pink">Choose plan</Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full -mt-1 max1200:-mt-10 max-w-[1200px] items-center justify-center mx-auto">
          {planRowsAndCols?.map((item) => (
            <div key={item.id} className="grid items-center w-full h-20 grid-cols-5 even:bg-white bg-light-freemium-pink rounded-3xl">
              <div className="flex items-center justify-center">
                <p className="w-[145px] text-sm font-bold mx-2">
                  {lang.get(`msg.${item.title}`)}
                </p>
              </div>

              {Children.toArray(item.data.map((checked) => (
                <div
                  className={cn(
                    'flex items-center justify-center',
                    checked.bgDiff && 'h-full',
                    checked.bgDiff && 'bg-[#FFF1F6]',
                  )}
                >
                  {checked.isChecked && <IconCheck size={25} className="p-1 text-white rounded-full bg-chat-primary" />}
                </div>
              )))}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
}

export default ChooseYourPlan;
