import { ColumnDef } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Button } from '@/components/ui/button';
import useTranslation from '@/hooks/useTranslation';
import useResultTabStore from '@/store/ResultsTabStore';

export interface IProject {
  conversation_id: string;
  snippet: string;
  score: string;
}

export const AISearchConversationColumns = () => {
  const { lang } = useTranslation();
  const setActiveTab = useResultTabStore((state) => state.setActiveTab);
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentTheme } = useTheme();

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const showConversation = (conversation_id: string) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    addQueryParams({ selected_transcript: conversation_id });
    setActiveTab('transcripts_tab');
  };

  const columns: ColumnDef<IProject>[] = [
    {
      accessorKey: 'conversation_id',
      header: () => (
        <Button
          variant="ghost"
          className="-ml-0.5"
        >
          {lang.get('msg.conversation')}
        </Button>
      ),
      cell: ({ row }) => (
        <div>
          <button
            style={{ backgroundColor: currentTheme?.primary }}
            onClick={() => showConversation(row?.original?.conversation_id)}
            type="button"
            className="flex items-center justify-center px-4 py-2 font-medium text-white rounded-md whitespace-nowrap"
          >
            {lang.get('msg.openConversation')}
          </button>
        </div>
      ),
    },
    {
      accessorKey: 'snippet',
      header: () => (
        <div className="flex items-center justify-center w-full">
          <Button
            variant="ghost"
          >
            {lang.get('msg.snippet')}
          </Button>
        </div>
      ),
      cell: ({ row }) => (
        <div>
          <p>{row.getValue('snippet')}</p>
        </div>
      ),
    },
    {
      accessorKey: 'score',
      header: () => (
        <div className="flex items-center justify-end w-full">
          <Button
            variant="ghost"
          >
            {lang.get('msg.score')}
          </Button>
        </div>
      ),
      cell: ({ row }) => (
        <div className="flex items-center justify-end w-full">
          <p
            className="font-medium whitespace-nowrap"
          >
            {row.getValue('score')}
          </p>
        </div>
      ),
    },
  ];
  return columns;
};
