/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

type State = {
  reportData: any;
  isStatisticChecked: boolean;
  isSummaryChecked: boolean;
  isQuestionsChecked: boolean;
  isKeyThemesChecked: boolean;
  isQuotesChecked: boolean;
  isOpportunitiesChecked: boolean;

  isQuestionDataSelected: boolean;
  questionData: any[],
};

  type Action = {
    setReportData: (value: any) => void;
    setIsStatisticChecked: (value: any) => void;
    setIsSummaryChecked: (value: any) => void;
    setIsQuestionsChecked: (value: any) => void;
    setIsKeyThemesChecked: (value: any) => void;
    setIsQuotesChecked: (value: any) => void;
    setIsOpportunitiesChecked: (value: any) => void;
    deleteQuestionData: (id: any) => void;
    setIsQuestionDataSelected: (value: any) => void;
    setQuestionData: (value:any) => void;
    clearReportData:() => void;
  };

const useReportStore = create<State & Action>((set) => ({
  reportData: [],
  setReportData: (value: any) => set({ reportData: value }),

  isStatisticChecked: false,
  setIsStatisticChecked: (value: boolean) => set({ isStatisticChecked: value }),

  isSummaryChecked: false,
  setIsSummaryChecked: (value: boolean) => set({ isSummaryChecked: value }),

  isQuestionsChecked: false,
  setIsQuestionsChecked: (value: boolean) => set({ isQuestionsChecked: value }),

  isKeyThemesChecked: false,
  setIsKeyThemesChecked: (value: boolean) => set({ isKeyThemesChecked: value }),

  isQuotesChecked: false,
  setIsQuotesChecked: (value: boolean) => set({ isQuotesChecked: value }),

  isOpportunitiesChecked: false,
  setIsOpportunitiesChecked: (value: boolean) => set({ isOpportunitiesChecked: value }),

  //   questions

  questionData: [],
  setQuestionData: (value: any) => set({ questionData: value }),

  deleteQuestionData: (id: any) => set((state) => {
    const questionData = state.questionData.filter((item) => item.question._id !== id);
    return {
      ...state,
      questionData,
    };
  }),

  isQuestionDataSelected: false,
  setIsQuestionDataSelected: (value: any) => set({ isQuestionDataSelected: value }),

  clearReportData: () => set({
    reportData: [],
    isStatisticChecked: false,
    isSummaryChecked: false,
    isKeyThemesChecked: false,
    isQuotesChecked: false,
    isOpportunitiesChecked: false,
    questionData: [],
    isQuestionDataSelected: false,
  }),

}));

export default useReportStore;
