import { Blocker } from 'react-router-dom';
import useTranslation from '@/hooks/useTranslation';
import { Button } from '../ui/button';
import useCreateProjectStore from '@/store/CreateProjectStore';

interface IModalProps {
  blocker: Blocker | undefined;
}

function UnsavedChangesModal({ blocker }: IModalProps) {
  const { lang } = useTranslation();
  const { setIsFormChanged } = useCreateProjectStore();

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-screen bg-black/30 z-100">
      <div className="flex flex-col py-10 px-10 text-center items-center justify-center w-full h-fit bg-white rounded-md max-w-[500px]">
        <h3 className="mb-3 text-xl font-semibold">Changes Not Saved</h3>
        <p>It looks like you have unsaved changes. Leaving this page will discard them. Do you want to proceed?</p>
        <div className="flex items-center gap-4 mt-10">
          <Button
            className=""
            variant="outline"
            onClick={() => {
              if (blocker?.proceed) {
                blocker?.proceed(); setIsFormChanged(false);
              }
            }}
          >
            {lang.get('msg.proceedAnyway')}
          </Button>
          <Button
            variant="default"
            onClick={() => {
              if (blocker?.reset) {
                blocker?.reset();
              }
            }}
          >
            {lang.get('msg.remainHere')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UnsavedChangesModal;
