interface IProbingQuestions {
  label: string;
  value: string;
}

const probingQuestions: IProbingQuestions[] = [
  {
    label: '0',
    value: '0',
  },
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
];

export default probingQuestions;
