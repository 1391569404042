import { IconEyeOff, IconEye } from '@tabler/icons-react';
import React from 'react';
import { cn } from '../../lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  hasError?: boolean;
  hasSuccess?: boolean;
  hasWarning?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, hasError, hasSuccess, hasWarning, ...props }, ref) => {
    const [inputType, setInputType] = React.useState(type);
    const isPassword = type === 'password';
    const togglePassword = () => {
      setInputType(inputType === 'password' ? '' : 'password');
    };

    return (
      <div className="relative flex items-center w-full text-gray-900">
        <input
          type={inputType}
          className={cn(
            'peer rounded-md border border-natural-700 transition-all flex h-10 w-full shadow-sm text-gray-700 focus-within:text-gray-900 bg-gray-50 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary disabled:pointer-events-none disabled:bg-gray-200 disabled:text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900',
            icon ? 'pl-11' : 'pl-3.5',
            isPassword ? 'pr-11' : 'pr-3.5',
            hasError && 'border-red-600',
            hasSuccess && '!shadow-hv-success',
            hasWarning && '!shadow-hv-warning',
            className,
          )}
          ref={ref}
          {...props}
        />
        {icon && (
          <span className="absolute left-3.5 text-gray-400 peer-focus:text-gray-900">{icon}</span>
        )}
        {isPassword && (
          <button
            type="button"
            className="absolute right-3.5 text-gray-400 peer-focus:text-gray-900"
            onClick={togglePassword}
          >
            {inputType ? <IconEyeOff /> : <IconEye />}
          </button>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export { Input };
