import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconInfoCircle, IconLoader2, IconPlus, IconTrash } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import * as Yup from 'yup';
import Switch from '@/components/switch/Switch';
import ToolTip from '@/components/tooltip/ToolTip';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { LangContext } from '@/context/LangProvider';
import { ECompanyThemeName, EProjectStatus, EStatusCode } from '@/enums';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import { useMutateProjectUpdatePanels } from '@/reactQuery/post';
import useCreateProjectStore from '@/store/CreateProjectStore';
import toCamelCase from '@/utils/toCamelCase';
import EmbedLinkPanelComponent from './EmbedLinkPanelComponent';
import QrCodePanelComponent from './QrCodePanelComponent';

const validationSchema = Yup.object().shape({
  panel: Yup.array().of(
    Yup.object().shape({
      confirmation_link: Yup.string().required('Confirmation link is required').url('Please enter a valid URL'),
      is_selected: Yup.boolean(),
      provider_name: Yup.string().required('Provider name is required'),
    }),
  ).required('At least one panel is required'),
});

interface IProjectPanel {
  singleProjectData: IProjectProps;
}

export interface IPanelList {
  confirmation_link: string;
  provider_name: string;
  is_selected?: boolean;
}

type FormData = {
  panel: IPanelList[];
};

function ProjectSharePanel({ singleProjectData }: IProjectPanel) {
  const { lang } = useContext(LangContext);
  const { project_id, workspace_id } = useParams();
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const isPanelEmpty = singleProjectData?.panel && Object.keys(singleProjectData.panel).length !== 0;
  const [isIntegrated, setIsIntegrated] = useState(isPanelEmpty);
  const { currentTheme, themeName } = useTheme();
  const transformedPanelsData: IPanelList[] = Object.values(singleProjectData?.panel);
  const [panelList, setPanelList] = useState<IPanelList[]>(transformedPanelsData || []);
  const disabled = isUserViewer || isProjectPublished || isProjectCompleted;
  const { setIsFormChanged, isFormChanged } = useCreateProjectStore();
  const interviewLinkRef = useRef<HTMLInputElement>(null);
  const [projectLink, setProjectLink] = useState('');
  const [borderColors, setBorderColors] = useState<string[]>([]);
  const [isProviderNameChanged, setIsProviderNameChanged] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const activateGreenBorder = (index: number) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Create a new array and update the specific index
    const newBorderColors = borderColors.map((color, i) => (i === index ? currentTheme?.primary as string : color));
    setBorderColors(newBorderColors);

    timeoutRef.current = setTimeout(() => {
      // Reset color back to default
      setBorderColors((prevColors) => prevColors.map((color, i) => (i === index ? '' : color)));
    }, 700);
  };

  const handleKeyPress = (index: number) => {
    activateGreenBorder(index);
  };

  const providerNameChangedShowSuccessMessage = () => {
    if (isProviderNameChanged) {
      toast({
        title: `✅ ${lang.get('msg.linkUpdatedSuccessfully')}`,
        description: `${lang.get('msg.noteThatTheLinkForThisPanelHasBeenUpdatedAndShouldBeSavedToReflectYourChanges')}`,
      });
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: { panel: panelList },
    resolver: yupResolver(validationSchema),
  });

  const { mutateAsync } = useMutateProjectUpdatePanels();

  const copyInterviewLink = async (link: string) => {
    if (interviewLinkRef.current) {
      try {
        await navigator.clipboard.writeText(link);
        toast({ description: `✅ ${lang.get('msg.linkCopied')}!` });
      } catch (err) {
        toast({ title: `${lang.get('msg.linkNotCopied')}`, variant: 'destructive' });
      }
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    const providerNames = data.panel.map((panel) => toCamelCase(panel.provider_name));
    const hasDuplicates = providerNames.some(
      (name, index) => providerNames.indexOf(name) !== index,
    );

    if (hasDuplicates) {
      toast({ description: lang.get('msg.providersNameMustBeUnique'), variant: 'destructive' });
      return;
    }

    const formattedData = data.panel.reduce((acc, panel) => {
      acc[toCamelCase(panel.provider_name)] = panel;
      return acc;
    }, {} as { [key: string]: IPanelList });

    const formattedDataWithPanel = { panel: formattedData };

    try {
      await mutateAsync({ data: formattedDataWithPanel, project_id });
      setIsFormChanged(false);
      setIsProviderNameChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const handleFormChanged = () => {
    setIsFormChanged(true);
  };

  const addPanel = () => {
    const newPanel = {
      is_selected: false,
      confirmation_link: '',
      provider_name: '',
    };

    setPanelList((prevList) => [...prevList, newPanel]);
    handleFormChanged();
  };

  const removePanel = (index: number) => {
    const updatedPanelList = panelList.filter((_, i) => i !== index);
    setPanelList(updatedPanelList);
    setValue('panel', updatedPanelList);
    handleFormChanged();
  };

  const handleToggle = async () => {
    try {
      if (isIntegrated) {
        await mutateAsync({ data: { panel: {} }, project_id });
        setPanelList([]);
      } else {
        addPanel();
      }
      handleFormChanged();
      setIsIntegrated(!isIntegrated);
      setIsFormChanged(false);
      setIsProviderNameChanged(false);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status !== EStatusCode.BAD_REQUEST) {
        toast({ description: lang.get('msg.errorPleaseTryAgain') });
      }
    }
  };

  const updatePanelField = (index: number, field: keyof IPanelList, value: string) => {
    setPanelList((prevList) => prevList.map((panel, i) => (i === index ? { ...panel, [field]: value } : panel)));
    handleFormChanged();
  };

  useEffect(() => {
    setValue('panel', panelList);
  }, [panelList, setValue]);

  useEffect(() => {
    const PROJECT_LINK = `${import.meta.env.VITE_ENVIRONMENT_CHAT}/${project_id}`;
    const PROJECT_LINK_EMPATHY = `${import.meta.env.VITE_ENVIRONMENT_CHAT_EMPATHY}/${project_id}`;

    const link = themeName === ECompanyThemeName.EMPATHY ? PROJECT_LINK_EMPATHY : PROJECT_LINK;

    setProjectLink(link);
  }, [themeName, project_id]);

  useEffect(() => {
    setBorderColors(new Array(panelList.length).fill(''));
  }, [panelList.length]);

  return (
    <div className="w-full h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full gap-6 pb-10">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1 w-fit">
                <ToolTip text="Select panel integration" className="ml-5" />
                <p className="flex items-center gap-1 mr-2 text-base font-medium">
                  {lang.get('msg.panelIntegration')}
                </p>
                <Switch func={handleToggle} value={isIntegrated} isDisabled={disabled} />
              </div>
              {isIntegrated && (
                <p className="flex items-center gap-1 text-sm">
                  <IconInfoCircle size={15} />
                  {lang.get('msg.panelIntegrationDescription')}
                </p>
              )}
            </div>
          </div>
        </div>

        {isIntegrated && (
          <div className="flex flex-col w-full gap-5">
            <div
              className="flex flex-col py-5 rounded-lg"
              style={{ backgroundColor: currentTheme?.['secondary-background'] }}
            >
              {React.Children.toArray(panelList?.map((item, index) => (
                <div className="flex flex-col w-full gap-3">
                  <div className="flex w-full gap-1 px-6 py-4 rounded-md">
                    <div className="flex items-start w-full gap-6 text-dark-text">
                      {/* -- provider name -- */}
                      <label className="flex flex-col w-1/4 gap-1">
                        <p
                          style={{ color: currentTheme?.['primary-text'] }}
                          className="text-sm font-medium"
                        >
                          {lang.get('msg.providerName')}
                          <span className="ml-0.5 text-red-600">*</span>
                        </p>
                        <Input
                          onKeyDown={() => { handleKeyPress(index); setIsProviderNameChanged(true); }}
                          value={item.provider_name}
                          hasError={!!errors.panel?.[index]?.provider_name?.message}
                          placeholder={lang.get('msg.providerName')}
                          {...register(`panel.${index}.provider_name`, {
                            onChange: (e) => updatePanelField(index, 'provider_name', e.target.value),
                          })}
                          onBlur={providerNameChangedShowSuccessMessage}
                          className="border-[3px]"
                          disabled={disabled}
                        />
                        <p
                          className={cn(
                            errors.panel?.[index]?.provider_name?.message ? 'flex' : 'hidden',
                            'text-red-600 text-xs font-semibold',
                          )}
                        >
                          {errors.panel?.[index]?.provider_name?.message}
                        </p>
                      </label>
                      {/* -- confirmation link -- */}
                      <label className="flex flex-col w-1/3 gap-1">
                        <p
                          style={{ color: currentTheme?.['primary-text'] }}
                          className="text-sm font-medium"
                        >
                          {lang.get('msg.confirmationLink')}
                          <span className="ml-0.5 text-red-600">*</span>
                        </p>
                        <Input
                          value={item.confirmation_link}
                          hasError={!!errors.panel?.[index]?.confirmation_link?.message}
                          placeholder={lang.get('msg.confirmationLink')}
                          {...register(`panel.${index}.confirmation_link`, {
                            onChange: (e) => updatePanelField(index, 'confirmation_link', e.target.value),
                          })}
                          disabled={disabled}
                          className="border-[3px]"
                        />
                        <p
                          className={cn(
                            errors.panel?.[index]?.confirmation_link?.message ? 'flex' : 'hidden',
                            'text-red-600 text-xs font-semibold',
                          )}
                        >
                          {errors.panel?.[index]?.confirmation_link?.message}
                        </p>
                      </label>
                      {/* -- interview link -- */}
                      <label className="flex flex-col w-1/2 gap-1">
                        <p
                          style={{ color: currentTheme?.['primary-text'] }}
                          className="text-sm font-medium"
                        >
                          {lang.get('msg.interviewLink')}
                        </p>
                        <Input
                          readOnly
                          value={`${projectLink}?source=${toCamelCase(item.provider_name)}`}
                          placeholder={lang.get('msg.interviewLink')}
                          ref={interviewLinkRef}
                          disabled={disabled}
                          style={{ borderColor: borderColors[index] }}
                          className={`border-[3px] ${borderColors[index]}`}
                        />
                      </label>

                      <div className="flex items-center gap-1 mt-6 mr-5">
                        <Button
                          variant="default"
                          onClick={() => copyInterviewLink(`${projectLink}?source=${toCamelCase(item.provider_name)}`)}
                          className="border-none"
                          type="button"
                        >
                          Copy
                        </Button>
                        {/* qr code button */}
                        <QrCodePanelComponent projectLink={projectLink} providerName={item?.provider_name} />

                        {/* embed button */}
                        <EmbedLinkPanelComponent projectLink={projectLink} providerName={item?.provider_name} index={index} />

                      </div>
                    </div>
                    {!disabled && (
                      <div className="mt-4 ml-auto cursor-pointer">
                        <IconTrash
                          size={24}
                          onClick={() => removePanel(index)}
                          className="mt-4 text-red-600"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )))}
            </div>
            {!disabled && (
              <div className="flex items-center w-full gap-3">
                <Button
                  type="button"
                  onClick={addPanel}
                  variant="outline"
                  size="sm"
                  disabled={disabled}
                  className="w-full text-dark-text"
                >
                  <IconPlus size="1rem" />
                  {lang.get('msg.addPanel')}
                </Button>
                {isFormChanged && (
                  <Button variant="default" size="sm" className="w-full" type="submit">
                    {isSubmitting && <IconLoader2 className="w-5 h-5 animate-spin" />}
                    {lang.get('msg.save')}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default ProjectSharePanel;
