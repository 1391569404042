import { Navigate, useLocation } from 'react-router-dom';
import { LangProvider } from '@/context/LangProvider';
import '@/css/scrollbar.css';
import Error404Page from '@/pages/error/Error404Page';
import Routes from '@/router/routes';
import useAuthStore from '@/store/AuthStore';
import useErrorStore from '@/store/ErrorStore';
import AuthContent from './AuthContent';

function AuthWrapper() {
  const location = useLocation();
  const { authToken } = useAuthStore();
  const { showError } = useErrorStore();

  if (showError) return <Error404Page />;

  return authToken ? (
    <LangProvider>
      <AuthContent />
    </LangProvider>
  ) : (
    <Navigate to={Routes.login.path} state={{ from: location.pathname }} replace />
  );
}

export default AuthWrapper;
