import { MoreHorizontal } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import useTranslation from '@/hooks/useTranslation';
import { Button } from '../ui/button';
import { useMutateDeleteProjectTheme } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';

interface IActionsProps {
  isUserAdmin: boolean;
  isUserOwner: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: any;
  handleOpenEditModal: (id: string) => void;
}

function ProjectBrandingTableActions({ isUserAdmin, isUserOwner, row, handleOpenEditModal }: IActionsProps) {
  const { lang } = useTranslation();
  const isUserAdminOrOwner = isUserAdmin || isUserOwner;

  const { mutateAsync: mutateAsyncDeleteTheme } = useMutateDeleteProjectTheme();

  const deleteThemeFunc = async (id: string) => {
    try {
      await mutateAsyncDeleteTheme(id);
      toast({ description: lang.get('msg.themeSuccessfullyRemoved') });
    } catch {
      toast({ description: lang.get('msg.errorPleaseTryAgain') });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="w-8 h-8 p-0">
          <MoreHorizontal className="w-4 h-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-10 w-52">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="cursor-pointer"
            onClick={() => handleOpenEditModal(row?.original?._id)}
          >
            {isUserAdminOrOwner && row?.original?.name !== 'Default' ? lang.get('msg.edit') : lang.get('msg.view')}
          </DropdownMenuItem>
          {row?.original?.name !== 'Default' && (
            <DropdownMenuItem
              onClick={() => deleteThemeFunc(row?.original?._id)}
              disabled={!isUserAdmin && !isUserOwner}
              className="cursor-pointer"
            >
              {lang.get('msg.delete')}
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default ProjectBrandingTableActions;
