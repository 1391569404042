interface Translation {
  [key: string]: string;
}

export const translateStatistics: Translation = {
  Afrikaans: 'Statistiek',
  Arabic: 'إحصائيات',
  Armenian: 'Վիճակագրություն',
  Azerbaijani: 'Statistika',
  Belarusian: 'Статыстыка',
  Bosnian: 'Statistika',
  Bulgarian: 'Статистика',
  Catalan: 'Estadístiques',
  Chinese: '统计',
  Croatian: 'Statistika',
  Czech: 'Statistiky',
  Danish: 'Statistik',
  Dutch: 'Statistieken',
  English: 'Statistics',
  Estonian: 'Statistika',
  Finnish: 'Tilastot',
  French: 'Statistiques',
  Galician: 'Estatísticas',
  German: 'Statistiken',
  Greek: 'Στατιστικά',
  Hebrew: 'סטטיסטיקות',
  Hindi: 'सांख्यिकी',
  Hungarian: 'Statisztika',
  Icelandic: 'Tölfræði',
  Italian: 'Statistiche',
  Japanese: '統計',
  Kannada: 'ಸಂಖ್ಯಾಶಾಸ್ತ್ರ',
  Kazakh: 'Статистика',
  Korean: '통계',
  Macedonian: 'Статистика',
  Malay: 'Statistik',
  Maori: 'Tauanga',
  Marathi: 'सांख्यिकी',
  Punjabi: 'ਸੰਖਿਆਵਾਂ',
  Persian: 'آمار',
  Polish: 'Statystyki',
  Portuguese: 'Estatísticas',
  Romanian: 'Statistică',
  Russian: 'Статистика',
  Serbian: 'Статистика',
  Slovak: 'Štatistika',
  Slovenian: 'Statistika',
  Spanish: 'Estadísticas',
  Swahili: 'Takwimu',
  Swedish: 'Statistik',
  Tamil: 'புள்ளிவிவரம்',
  Thai: 'สถิติ',
  Turkish: 'İstatistikler',
  Ukrainian: 'Статистика',
  Urdu: 'اعداد و شمار',
  Vietnamese: 'Thống kê',
  Welsh: 'Ystadegau',
};

export const translateTotal: Translation = {
  Afrikaans: 'Totaal',
  Arabic: 'المجموع',
  Armenian: 'Ընդհանուր',
  Azerbaijani: 'Ümumi',
  Belarusian: 'Усяго',
  Bosnian: 'Ukupno',
  Bulgarian: 'Общо',
  Catalan: 'Total',
  Chinese: '总',
  Croatian: 'Ukupno',
  Czech: 'Celkem',
  Danish: 'Total',
  Dutch: 'Totaal',
  English: 'Total',
  Estonian: 'Kokku',
  Finnish: 'Yhteensä',
  French: 'Total',
  Galician: 'Total',
  German: 'Gesamt',
  Greek: 'Σύνολο',
  Hebrew: 'סה"כ',
  Hindi: 'कुल',
  Hungarian: 'Teljes',
  Icelandic: 'Heildar',
  Italian: 'Totale',
  Japanese: '合計',
  Kannada: 'ಒಟ್ಟು',
  Kazakh: 'Жалпы',
  Korean: '합계',
  Macedonian: 'Вкупно',
  Malay: 'Jumlah',
  Maori: 'Te Katoa',
  Marathi: 'एकूण',
  Punjabi: 'ਕੁੱਲ',
  Persian: 'کل',
  Polish: 'Suma',
  Portuguese: 'Total',
  Romanian: 'Total',
  Russian: 'Всего',
  Serbian: 'Укупно',
  Slovak: 'Celkom',
  Slovenian: 'Skupaj',
  Spanish: 'Total',
  Swahili: 'Jumla',
  Swedish: 'Totalt',
  Tamil: 'மொத்தம்',
  Thai: 'รวม',
  Turkish: 'Toplam',
  Ukrainian: 'Всього',
  Urdu: 'کل',
  Vietnamese: 'Tổng cộng',
  Welsh: 'Cyfanswm',
};

export const translateCompletedInterviews: Translation = {
  Afrikaans: 'Voltooide onderhoude',
  Arabic: 'المقابلات المكتملة',
  Armenian: 'Ավարտված հարցումներ',
  Azerbaijani: 'Tamamlanmış mülakatlar',
  Belarusian: 'Завершаныя інтэрв’ю',
  Bosnian: 'Završeni intervjui',
  Bulgarian: 'Завършени интервюта',
  Catalan: 'Entrevistes completades',
  Chinese: '已完成的面试',
  Croatian: 'Završeni razgovori',
  Czech: 'Dokončené rozhovory',
  Danish: 'Afsluttede interviews',
  Dutch: 'Voltooide interviews',
  English: 'Completed interviews',
  Estonian: 'Lõpetatud intervjuud',
  Finnish: 'Valmiit haastattelut',
  French: 'Entretiens terminés',
  Galician: 'Entrevistas completadas',
  German: 'Abgeschlossene Interviews',
  Greek: 'Ολοκληρωμένες συνεντεύξεις',
  Hebrew: 'ראיונות הושלמו',
  Hindi: 'पूर्ण किए गए साक्षात्कार',
  Hungarian: 'Befejezett interjúk',
  Icelandic: 'Lokið viðtöl',
  Italian: 'Interviste completate',
  Japanese: '完了した面接',
  Kannada: 'ಪೂರ್ಣಗೊಂಡ ಸಂವಾದಗಳು',
  Kazakh: 'Жүргізілген интервьюлар',
  Korean: '완료된 인터뷰',
  Macedonian: 'Завршени интервјуа',
  Malay: 'Wawancara Selesai',
  Maori: 'Ngā mahi haere kē',
  Marathi: 'पूर्ण केलेले मुलाखत',
  Punjabi: 'ਮੁਕੰਮਲ ਇੰਟਰਵਿਊ',
  Persian: 'مصاحبه های تکمیل شده',
  Polish: 'Zakończone wywiady',
  Portuguese: 'Entrevistas concluídas',
  Romanian: 'Interviuri completate',
  Russian: 'Завершенные интервью',
  Serbian: 'Завршени интервјуи',
  Slovak: 'Dokončené pohovory',
  Slovenian: 'Končani pogovori',
  Spanish: 'Entrevistas completadas',
  Swahili: 'Mahojiano yaliyokamilika',
  Swedish: 'Avslutade intervjuer',
  Tamil: 'முடிந்த பேட்டிகள்',
  Thai: 'สัมภาษณ์เสร็จสมบูรณ์',
  Turkish: 'Tamamlanan mülakatlar',
  Ukrainian: 'Завершені інтерв’ю',
  Urdu: 'مکمل انٹرویوز',
  Vietnamese: 'Các cuộc phỏng vấn đã hoàn thành',
  Welsh: 'Cyfweliadau wedi eu cwblhau',
};

export const translateStartedInterviews: Translation = {
  Afrikaans: 'Begin onderhoude',
  Arabic: 'المقابلات المباشرة',
  Armenian: 'Սկսված հարցումներ',
  Azerbaijani: 'Başlanmış mülakatlar',
  Belarusian: 'Пачатыя інтэрв’ю',
  Bosnian: 'Početi intervjui',
  Bulgarian: 'Започнати интервюта',
  Catalan: 'Entrevistes iniciades',
  Chinese: '开始面试',
  Croatian: 'Započeti razgovori',
  Czech: 'Začaté rozhovory',
  Danish: 'Startede interviews',
  Dutch: 'Gestarte interviews',
  English: 'Started interviews',
  Estonian: 'Alustatud intervjuud',
  Finnish: 'Alkanut haastattelut',
  French: 'Entretiens commencés',
  Galician: 'Entrevistas comezadas',
  German: 'Gestartete Interviews',
  Greek: 'Ξεκίνησε συνεντεύξεις',
  Hebrew: 'ראיונות התחילו',
  Hindi: 'शुरू किए गए साक्षात्कार',
  Hungarian: 'Elkezdett interjúk',
  Icelandic: 'Byrjað viðtöl',
  Italian: 'Interviste iniziate',
  Japanese: '開始された面接',
  Kannada: 'ಆರಂಭವಾದ ಸಂವಾದಗಳು',
  Kazakh: 'Басталған интервьюлар',
  Korean: '시작된 인터뷰',
  Macedonian: 'Почнати интервјуа',
  Malay: 'Wawancara Bermula',
  Maori: 'Kua tīmata ngā whakawhiti kōrero',
  Marathi: 'सुरू केलेले मुलाखत',
  Punjabi: 'ਸ਼ੁਰੂ ਹੋਈਆਂ ਇੰਟਰਵਿਊ',
  Persian: 'مصاحبه های شروع شده',
  Polish: 'Rozpoczęte wywiady',
  Portuguese: 'Entrevistas iniciadas',
  Romanian: 'Interviuri începute',
  Russian: 'Начатые интервью',
  Serbian: 'Почети интервјуи',
  Slovak: 'Začaté pohovory',
  Slovenian: 'Začeti pogovori',
  Spanish: 'Entrevistas comenzadas',
  Swahili: 'Mahojiano Yaliyoanza',
  Swedish: 'Påbörjade intervjuer',
  Tamil: 'தொடங்கிய பேட்டிகள்',
  Thai: 'เริ่มสัมภาษณ์',
  Turkish: 'Başlayan mülakatlar',
  Ukrainian: 'Початі інтерв’ю',
  Urdu: 'شروع ہوئے انٹرویوز',
  Vietnamese: 'Các cuộc phỏng vấn đã bắt đầu',
  Welsh: 'Cyfweliadau wedi dechrau',
};

export const translateCompletionRate: Translation = {
  Afrikaans: 'Voltooiingsyfer',
  Arabic: 'معدل الإنجاز',
  Armenian: 'Ավարտման տոկոսադրույքը',
  Azerbaijani: 'Tamamlama dərəcəsi',
  Belarusian: 'Хуткасць выканання',
  Bosnian: 'Stopa završetka',
  Bulgarian: 'Степен на завършване',
  Catalan: 'Taxa de finalització',
  Chinese: '完成率',
  Croatian: 'Stopa završetka',
  Czech: 'Míra dokončení',
  Danish: 'Gennemførelsesgrad',
  Dutch: 'Voltooiingspercentage',
  English: 'Completion rate',
  Estonian: 'Täitmise määr',
  Finnish: 'Valmistumisaste',
  French: 'Taux de complétion',
  Galician: 'Taxa de realización',
  German: 'Abschlussquote',
  Greek: 'Ποσοστό ολοκλήρωσης',
  Hebrew: 'שיעור השלמה',
  Hindi: 'समापन दर',
  Hungarian: 'Teljesítési arány',
  Icelandic: 'Fullnaðarhlutfall',
  Italian: 'Tasso di completamento',
  Japanese: '完了率',
  Kannada: 'ಪೂರ್ಣಗೊಳಿಸುವಿಕೆಯ ಪ್ರಮಾಣ',
  Kazakh: 'Аяқтау жылдамдығы',
  Korean: '진척률',
  Macedonian: 'Стапка на завршување',
  Malay: 'Kadar penyiapan',
  Maori: 'Reeti whakaotinga',
  Marathi: 'पूर्ण होण्याचा दर',
  Punjabi: 'ਮੁਕੰਮਲ ਹੋਣ ਦੀ ਦਰ',
  Persian: 'نرخ تکمیل',
  Polish: 'Stopień ukończenia',
  Portuguese: 'Taxa de realização',
  Romanian: 'Rata de finalizare',
  Russian: 'Скорость завершения',
  Serbian: 'Стопа завршавања',
  Slovak: 'Miera dokončenia',
  Slovenian: 'Stopnja dokončanja',
  Spanish: 'Tasa de finalización',
  Swahili: 'Kiwango cha kukamilika',
  Swedish: 'Färdigställandegrad',
  Tamil: 'நிறைவு விகிதம்',
  Thai: 'อัตราความสำเร็จ',
  Turkish: 'Tamamlama oranı',
  Ukrainian: 'Швидкість виконання',
  Urdu: 'تکمیل کی شرح',
  Vietnamese: 'Tỷ lệ hoàn thành',
  Welsh: 'Cyfradd cwblhau',
};

export const translateAvgTimeToCompletion: Translation = {
  Afrikaans: 'Gem. tyd tot voltooiing',
  Arabic: 'متوسط الوقت حتى الانتهاء',
  Armenian: 'Ավարտի միջին ժամանակ',
  Azerbaijani: 'Başa çatmağa orta vaxt',
  Belarusian: 'Сярэдні час да завяршэння',
  Bosnian: 'Prosječno vrijeme do završetka',
  Bulgarian: 'Средно време за завършване',
  Catalan: 'Temps mitjà de finalització',
  Chinese: '平均完成时间',
  Croatian: 'Prosječno vrijeme do završetka',
  Czech: 'Průměrný čas do dokončení',
  Danish: 'Gns. tid til afslutning',
  Dutch: 'Gem. tijd tot voltooiing',
  English: 'Avg. time to completion',
  Estonian: 'Keskmine aeg lõpetamiseni',
  Finnish: 'Keskimääräinen aika valmistumiseen',
  French: 'Temps moyen de complétion',
  Galician: 'Tempo medio de finalización',
  German: 'Durchschnittliche Zeit bis zur Fertigstellung',
  Greek: 'Μέσος χρόνος ολοκλήρωσης',
  Hebrew: 'זמן ממוצע עד השלמה',
  Hindi: 'पूरा होने तक का औसत समय',
  Hungarian: 'Átlagos idő a befejezésig',
  Icelandic: 'Meðaltími til lokunar',
  Italian: 'Tempo medio per il completamento',
  Japanese: '完了までの平均時間',
  Kannada: 'ಪೂರ್ತಿಗೆ ಸಮಯದ ಸರಾಸರಿ',
  Kazakh: 'Жақтығына дейінгі ортақ уақыт',
  Korean: '평균 완료 시간',
  Macedonian: 'Просечно време до завршување',
  Malay: 'Purata masa hingga penyelesaian',
  Maori: 'Wā o te wā o te whakakapia',
  Marathi: 'पूर्णता पर्यंतचा सरासरी वेळ',
  Punjabi: 'ਮੁਕੰਮਲਤਾ ਤੱਕ ਸਮਾਨ ਸਮਾਨ',
  Persian: 'متوسط زمان تا پایان',
  Polish: 'Średni czas do zakończenia',
  Portuguese: 'Tempo médio para conclusão',
  Romanian: 'Timp mediu până la finalizare',
  Russian: 'Среднее время до завершения',
  Serbian: 'Процењено време до завршетка',
  Slovak: 'Priemerný čas do dokončenia',
  Slovenian: 'Povprečni čas do konca',
  Spanish: 'Tiempo promedio de finalización',
  Swahili: 'Wakati wa wastani wa kumaliza',
  Swedish: 'Genomsnittlig tid till avslut',
  Tamil: 'முடிப்புக்கு சராசரி நேரம்',
  Thai: 'เวลาเฉลี่ยถึงเสร็จสิ้น',
  Turkish: 'Tamamlanma süresinin ortalama zamanı',
  Ukrainian: 'Середній час до завершення',
  Urdu: 'مکمل ہونے کا اوسط وقت',
  Vietnamese: 'Thời gian trung bình đến hoàn thành',
  Welsh: 'Amser cyfartalog i gwblhau',
};

export const translateSummary: Translation = {
  Afrikaans: 'Opsomming',
  Arabic: 'ملخص',
  Armenian: 'Ամփոփում',
  Azerbaijani: 'Nəticə',
  Belarusian: 'Суадносіны',
  Bosnian: 'Sažetak',
  Bulgarian: 'Резюме',
  Catalan: 'Resum',
  Chinese: '摘要',
  Croatian: 'Sažetak',
  Czech: 'Souhrn',
  Danish: 'Resumé',
  Dutch: 'Samenvatting',
  English: 'Summary',
  Estonian: 'Kokkuvõte',
  Finnish: 'Yhteenveto',
  French: 'Résumé',
  Galician: 'Resumo',
  German: 'Zusammenfassung',
  Greek: 'Περίληψη',
  Hebrew: 'סיכום',
  Hindi: 'सारांश',
  Hungarian: 'Összefoglaló',
  Icelandic: 'Yfirlit',
  Italian: 'Sommario',
  Japanese: '概要',
  Kannada: 'ಸಂಕ್ಷಿಪ್ತವನ್ನು',
  Kazakh: 'Жалпы есеп',
  Korean: '개요',
  Macedonian: 'Сажеток',
  Malay: 'Ringkasan',
  Maori: 'Whakarāpopototanga',
  Marathi: 'सारांश',
  Punjabi: 'ਸੰਖੇਪ',
  Persian: 'خلاصه',
  Polish: 'Podsumowanie',
  Portuguese: 'Resumo',
  Romanian: 'Rezumat',
  Russian: 'Резюме',
  Serbian: 'Резиме',
  Slovak: 'Zhrnutie',
  Slovenian: 'Povzetek',
  Spanish: 'Resumen',
  Swahili: 'Muhtasari',
  Swedish: 'Sammanfattning',
  Tamil: 'சுருக்கம்',
  Thai: 'สรุป',
  Turkish: 'Özet',
  Ukrainian: 'Резюме',
  Urdu: 'خلاصہ',
  Vietnamese: 'Tóm tắt',
  Welsh: 'Crynodeb',
};

export const translateKeyThemes: Translation = {
  Afrikaans: 'Kern temas',
  Arabic: 'مواضيع رئيسية',
  Armenian: 'Հիմնական թեմաներ',
  Azerbaijani: 'Əsas mövzular',
  Belarusian: 'Ключавыя тэмы',
  Bosnian: 'Ključne teme',
  Bulgarian: 'Ключови теми',
  Catalan: 'Temes clau',
  Chinese: '关键主题',
  Croatian: 'Ključne teme',
  Czech: 'Klíčová témata',
  Danish: 'Centrale temaer',
  Dutch: 'Belangrijke thema\'s',
  English: 'Key themes',
  Estonian: 'Peamised teemad',
  Finnish: 'Avainaiheet',
  French: 'Thèmes clés',
  Galician: 'Temas clave',
  German: 'Hauptthemen',
  Greek: 'Κύρια θέματα',
  Hebrew: 'נושאים מרכזיים',
  Hindi: 'मुख्य विषय',
  Hungarian: 'Kulcs témák',
  Icelandic: 'Lykilþættir',
  Italian: 'Temi chiave',
  Japanese: 'キーテーマ',
  Kannada: 'ಪ್ರಧಾನ ಥೀಮ್‌ಗಳು',
  Kazakh: 'Негізгі тақырыптар',
  Korean: '주요 주제',
  Macedonian: 'Клучни теми',
  Malay: 'Tema utama',
  Maori: 'Kaupapa matua',
  Marathi: 'मुख्य थीम्स',
  Punjabi: 'ਮੁੱਖ ਵਿਸ਼ਾਂ',
  Persian: 'موضوعات کلیدی',
  Polish: 'Kluczowe tematy',
  Portuguese: 'Temas chave',
  Romanian: 'Tematici cheie',
  Russian: 'Ключевые темы',
  Serbian: 'Кључне теме',
  Slovak: 'Kľúčové témy',
  Slovenian: 'Ključne teme',
  Spanish: 'Temas clave',
  Swahili: 'Mada muhimu',
  Swedish: 'Nyckelteman',
  Tamil: 'முக்கிய தொகுப்புகள்',
  Thai: 'หัวข้อหลัก',
  Turkish: 'Ana temalar',
  Ukrainian: 'Ключові теми',
  Urdu: 'کلیدی موضوعات',
  Vietnamese: 'Chủ đề chính',
  Welsh: 'Themâu allweddol',
};

export const translatePatternsAndTrends: Translation = {
  Afrikaans: 'Patrone en Tendense',
  Arabic: 'أنماط واتجاهات',
  Armenian: 'Կաղապարներ և Թրենդներ',
  Azerbaijani: 'Nümunələr və Trendlər',
  Belarusian: 'Патэрны і Трэнды',
  Bosnian: 'Šabloni i Trendovi',
  Bulgarian: 'Модели и Тенденции',
  Catalan: 'Patrons i Tendències',
  Chinese: '模式和趋势',
  Croatian: 'Uzorci i Trendovi',
  Czech: 'Vzory a Trendy',
  Danish: 'Mønstre og Tendenser',
  Dutch: 'Patronen en Trends',
  English: 'Patterns and Trends',
  Estonian: 'Mustrid ja Trendid',
  Finnish: 'Mallit ja Trendit',
  French: 'Modèles et Tendances',
  Galician: 'Patróns e Tendencias',
  German: 'Muster und Trends',
  Greek: 'Πρότυπα και Τάσεις',
  Hebrew: 'תבניות ומגמות',
  Hindi: 'पैटर्न्स और ट्रेंड्स',
  Hungarian: 'Minták és Trendek',
  Icelandic: 'Mynstur og Stefnur',
  Italian: 'Modelli e Tendenze',
  Japanese: 'パターンとトレンド',
  Kannada: 'ನಮೂನೆಗಳು ಮತ್ತು ಪ್ರವೃತ್ತಿಗಳು',
  Kazakh: 'Үлгілер мен Трендтер',
  Korean: '패턴 및 트렌드',
  Macedonian: 'Шаблони и Трендови',
  Malay: 'Corak dan Trend',
  Maori: 'Ngā Tauira me ngā Ia',
  Marathi: 'नमुने आणि ट्रेंड्स',
  Persian: 'الگوها و روندها',
  Polish: 'Wzory i Trendy',
  Portuguese: 'Padrões e Tendências',
  Romanian: 'Tipare și Tendințe',
  Russian: 'Шаблоны и Тренды',
  Serbian: 'Шаблони и Трендови',
  Slovak: 'Vzory a Trendy',
  Slovenian: 'Vzorci in Trendi',
  Spanish: 'Patrones y Tendencias',
  Swahili: 'Mifumo na Mitindo',
  Swedish: 'Mönster och Trender',
  Tamil: 'மாதிரிகள் மற்றும் போக்குகள்',
  Thai: 'รูปแบบและแนวโน้ม',
  Turkish: 'Kalıplar ve Trendler',
  Ukrainian: 'Шаблони і Тренди',
  Urdu: 'پیٹرنز اور رجحانات',
  Vietnamese: 'Mô hình và Xu hướng',
  Welsh: 'Patrymau a Thueddiadau',
};

export const translateDivergentViewsAndOutliers: Translation = {
  Afrikaans: 'Afwykende Menings en Uitskieters',
  Arabic: 'آراء متباينة وحالات شاذة',
  Armenian: 'Տարբերվող Դիտումներ և Արտառոցներ',
  Azerbaijani: 'Fərqli Baxışlar və Fərqlənənlər',
  Belarusian: 'Разнастайныя Погляды і Выбітныя',
  Bosnian: 'Divergentna Mišljenja i Izdvojeni Podaci',
  Bulgarian: 'Различаващи се Мнения и Аномалии',
  Catalan: 'Opinions Divergents i Valors Extrems',
  Chinese: '分歧意见和离群值',
  Croatian: 'Različita Mišljenja i Odstupanja',
  Czech: 'Odlišné Názory a Výstřední Hodnoty',
  Danish: 'Divergerende Synspunkter og Afvigere',
  Dutch: 'Divergerende Meningen en Uitschieters',
  English: 'Divergent Views and Outliers',
  Estonian: 'Erinevad Arvamused ja Äärmused',
  Finnish: 'Eriävät Mielipiteet ja Poikkeamat',
  French: 'Points de Vue Divergents et Valeurs Atypiques',
  Galician: 'Puntos de Vista Diverxentes e Outliers',
  German: 'Abweichende Ansichten und Ausreißer',
  Greek: 'Αντιφατικές Απόψεις και Ακραίες Τιμές',
  Hebrew: 'דעות שונות וחריגים',
  Hindi: 'विभिन्न दृष्टिकोण और अपवाद',
  Hungarian: 'Eltérő Vélemények és Kiemelkedések',
  Icelandic: 'Ólík Viðhorf og Útsetningar',
  Italian: 'Punti di Vista Divergenti e Valori Anomali',
  Japanese: '異なる見解と外れ値',
  Kannada: 'ವಿಭಿನ್ನ ದೃಷ್ಟಿಕೋನಗಳು ಮತ್ತು ವೈಚಾರಿಕ ಅತಿರೇಕಗಳು',
  Kazakh: 'Қарама-Қайшы Көзқарастар және Ерекшеліктер',
  Korean: '상이한 견해와 이상치',
  Macedonian: 'Различни Мислења и Изолирани Податоци',
  Malay: 'Pandangan Berbeza dan Keluar Bias',
  Maori: 'Ngā Tirohanga Rerekē me ngā Ahurei',
  Marathi: 'विभिन्न मते आणि अपवाद',
  Persian: 'دیدگاه‌های متفاوت و موارد استثنایی',
  Polish: 'Rozbieżne Poglądy i Wartości Odstające',
  Portuguese: 'Visões Divergentes e Outliers',
  Romanian: 'Puncte de Vedere Divergente și Valori Anormale',
  Russian: 'Расходящиеся Мнения и Аномалии',
  Serbian: 'Различита Мишљења и Изузетни Подаци',
  Slovak: 'Rozdielne Názory a Odľahlé Hodnoty',
  Slovenian: 'Različna Mnenja in Odstopanja',
  Spanish: 'Puntos de Vista Divergentes y Valores Atípicos',
  Swahili: 'Mitazamo Tofauti na Vipimo vya Nje',
  Swedish: 'Avvikande Åsikter och Udda Värden',
  Tamil: 'மாறுபட்ட கருத்துக்கள் மற்றும் அசாதாரணமான மதிப்புகள்',
  Thai: 'มุมมองที่แตกต่างและค่าผิดปกติ',
  Turkish: 'Farklı Görüşler ve Aykırılıklar',
  Ukrainian: 'Відмінні Думки та Відхилення',
  Urdu: 'مختلف خیالات اور غیر معمولی معاملات',
  Vietnamese: 'Quan Điểm Khác Biệt và Giá Trị Ngoại Lệ',
  Welsh: 'Golygfeydd Amrywiol a Gwerthoedd Eithriadol',
};

export const translateInterestingQuotes: Translation = {
  Afrikaans: 'Interessante aanhalings',
  Arabic: 'اقتباسات مثيرة للاهتمام',
  Armenian: 'Ինտերեսանտ խոսաքանդություններ',
  Azerbaijani: 'Marğub sitatlar',
  Belarusian: 'Цікавыя цытаты',
  Bosnian: 'Zanimljivi citati',
  Bulgarian: 'Интересни цитати',
  Catalan: 'Cites interessants',
  Chinese: '有趣的引语',
  Croatian: 'Zanimljive izreke',
  Czech: 'Zajímavé citáty',
  Danish: 'Interessante citater',
  Dutch: 'Interessante citaten',
  English: 'Interesting quotes',
  Estonian: 'Huvitavad tsitaadid',
  Finnish: 'Mielenkiintoisia lainauksia',
  French: 'Citations intéressantes',
  Galician: 'Citas interesantes',
  German: 'Interessante Zitate',
  Greek: 'Ενδιαφέροντα αποφθέγματα',
  Hebrew: 'ציטוטים מעניינים',
  Hindi: 'रोचक उद्धरण',
  Hungarian: 'Érdekes idézetek',
  Icelandic: 'Áhugaverðar tilvitnanir',
  Italian: 'Citazioni interessanti',
  Japanese: '興味深い引用',
  Kannada: 'ಆಸಕ್ತಿಯ ಉಲ್ಲೇಖಗಳು',
  Kazakh: 'Қызықты сөздер',
  Korean: '흥미로운 인용구',
  Macedonian: 'Интересни цитати',
  Malay: 'Ucapan menarik',
  Maori: 'Whakamāramatanga whakataukī',
  Marathi: 'आकर्षक उद्धरणे',
  Punjabi: 'ਦਿਲਚਸਪ ਹੁਣਰਾਂ',
  Persian: 'نقل قول های جالب',
  Polish: 'Interesujące cytaty',
  Portuguese: 'Citações interessantes',
  Romanian: 'Citate interesante',
  Russian: 'Интересные цитаты',
  Serbian: 'Интересантне цитате',
  Slovak: 'Zaujímavé citáty',
  Slovenian: 'Zanimivi citati',
  Spanish: 'Frases interesantes',
  Swahili: 'Nukuu za kuvutia',
  Swedish: 'Intressanta citat',
  Tamil: 'ஆர்வம் மெய்யிய மொழிபெயர்ப்புகள்',
  Thai: 'คำพูดที่น่าสนใจ',
  Turkish: 'İlginç alıntılar',
  Ukrainian: 'Цікаві цитати',
  Urdu: 'دلچسپ حوار',
  Vietnamese: 'Trích dẫn thú vị',
  Welsh: 'Dyfyniadau diddorol',
};

export const translateQuote: Translation = {
  Afrikaans: 'Aanhalings',
  Arabic: 'اقتباس',
  Armenian: 'Մեկնաբանություն',
  Azerbaijani: 'Sitat',
  Belarusian: 'Цытата',
  Bosnian: 'Citat',
  Bulgarian: 'Цитат',
  Catalan: 'Cita',
  Chinese: '引语',
  Croatian: 'Citirati',
  Czech: 'Citace',
  Danish: 'Citat',
  Dutch: 'Citaat',
  English: 'Quote',
  Estonian: 'Tsitaat',
  Finnish: 'Lainaus',
  French: 'Citation',
  Galician: 'Cita',
  German: 'Zitat',
  Greek: 'Παράθεση',
  Hebrew: 'ציטוט',
  Hindi: 'उद्धरण',
  Hungarian: 'Idézet',
  Icelandic: 'Tilvitnun',
  Italian: 'Citazione',
  Japanese: '引用',
  Kannada: 'ಉಲ್ಲೇಖ',
  Kazakh: 'Сөз',
  Korean: '인용구',
  Macedonian: 'Цитат',
  Malay: 'Kutipan',
  Maori: 'Whakataukī',
  Marathi: 'उद्धर',
  Punjabi: 'ਹਵਾਲਾ',
  Persian: 'نقل قول',
  Polish: 'Cytat',
  Portuguese: 'Citação',
  Romanian: 'Citat',
  Russian: 'Цитата',
  Serbian: 'Цитат',
  Slovak: 'Citát',
  Slovenian: 'Citat',
  Spanish: 'Cita',
  Swahili: 'Nukuu',
  Swedish: 'Citat',
  Tamil: 'கோப்பு',
  Thai: 'คำคม',
  Turkish: 'Alıntı',
  Ukrainian: 'Цитата',
  Urdu: 'حوالہ',
  Vietnamese: 'Trích dẫn',
  Welsh: 'Dyfyniad',
};

export const translateOpportunities: Translation = {
  Afrikaans: 'Geleenthede',
  Arabic: 'فرص',
  Armenian: 'Օրինակներ',
  Azerbaijani: 'Fırsatlar',
  Belarusian: 'Магчымасці',
  Bosnian: 'Prilike',
  Bulgarian: 'Възможности',
  Catalan: 'Oportunitats',
  Chinese: '机会',
  Croatian: 'Prilike',
  Czech: 'Příležitosti',
  Danish: 'Muligheder',
  Dutch: 'Kansen',
  English: 'Opportunities',
  Estonian: 'Võimalused',
  Finnish: 'Mahdollisuudet',
  French: 'Opportunités',
  Galician: 'Oportunidades',
  German: 'Chancen',
  Greek: 'Ευκαιρίες',
  Hebrew: 'הזדמנויות',
  Hindi: 'अवसर',
  Hungarian: 'Lehetőségek',
  Icelandic: 'Tækifæri',
  Italian: 'Opportunità',
  Japanese: '機会',
  Kannada: 'ಅವಕಾಶಗಳು',
  Kazakh: 'Мүмкіндіктер',
  Korean: '기회',
  Macedonian: 'Можности',
  Malay: 'Peluang',
  Maori: 'Tauhokohoko',
  Marathi: 'संधी',
  Punjabi: 'ਮੌਕੇ',
  Persian: 'فرصت ها',
  Polish: 'Okazje',
  Portuguese: 'Oportunidades',
  Romanian: 'Oportunități',
  Russian: 'Возможности',
  Serbian: 'Прилике',
  Slovak: 'Príležitosti',
  Slovenian: 'Priložnosti',
  Spanish: 'Oportunidades',
  Swahili: 'Fursa',
  Swedish: 'Möjligheter',
  Tamil: 'வாயில்கள்',
  Thai: 'โอกาส',
  Turkish: 'Fırsatlar',
  Ukrainian: 'Можливості',
  Urdu: 'مواقع',
  Vietnamese: 'Cơ hội',
  Welsh: 'Cyfleoedd',
};

export const translateAddToReport: Translation = {
  Afrikaans: 'Voeg by verslag',
  Arabic: 'أضف إلى التقرير',
  Armenian: 'Ավելացնել դեպի հաշվետվություն',
  Azerbaijani: 'Hesabatə əlavə edin',
  Belarusian: 'Дадаць у справаздачу',
  Bosnian: 'Dodaj u izvještaj',
  Bulgarian: 'Добави към доклада',
  Catalan: 'Afegir al informe',
  Chinese: '添加到报告',
  Croatian: 'Dodaj u izvješće',
  Czech: 'Přidat do zprávy',
  Danish: 'Tilføj til rapport',
  Dutch: 'Toevoegen aan rapport',
  English: 'Add to report',
  Estonian: 'Lisa aruandesse',
  Finnish: 'Lisää raporttiin',
  French: 'Ajouter au rapport',
  Galician: 'Engadir ao informe',
  German: 'Zum Bericht hinzufügen',
  Greek: 'Προσθήκη στην αναφορά',
  Hebrew: 'הוסף לדוח',
  Hindi: 'रिपोर्ट में जोड़ें',
  Hungarian: 'Hozzáadás a jelentéshez',
  Icelandic: 'Bæta við skýrslu',
  Italian: 'Aggiungi al rapporto',
  Japanese: 'レポートに追加',
  Kannada: 'ವರದಿಗೆ ಸೇರಿಸಿ',
  Kazakh: 'Есепке қосу',
  Korean: '보고서에 추가',
  Macedonian: 'Додади во извештајот',
  Malay: 'Tambah ke laporan',
  Maori: 'Tāpiri ki te pūrongo',
  Marathi: 'अहवालात जोडा',
  Punjabi: 'ਰਿਪੋਰਟ ਵਿੱਚ ਸ਼ਾਮਲ ਕਰੋ',
  Persian: 'افزودن به گزارش',
  Polish: 'Dodaj do raportu',
  Portuguese: 'Adicionar ao relatório',
  Romanian: 'Adăugare la raport',
  Russian: 'Добавить в отчет',
  Serbian: 'Додај у извештај',
  Slovak: 'Pridať do správy',
  Slovenian: 'Dodaj v poročilo',
  Spanish: 'Agregar al informe',
  Swahili: 'Ongeza kwa ripoti',
  Swedish: 'Lägg till i rapporten',
  Tamil: 'அறிக்கைக்கு சேர்க்க',
  Thai: 'เพิ่มเข้ารายงาน',
  Turkish: 'Rapora ekle',
  Ukrainian: 'Додати до звіту',
  Urdu: 'رپورٹ میں شامل کریں',
  Vietnamese: 'Thêm vào báo cáo',
  Welsh: 'Ychwanegu at yr adroddiad',
};

export const translateCategories: Translation = {
  Afrikaans: 'Kategorieë',
  Arabic: 'التصنيفات',
  Armenian: 'Կատեգորիաներ',
  Azerbaijani: 'Kateqoriyalar',
  Belarusian: 'Катэгорыі',
  Bosnian: 'Kategorije',
  Bulgarian: 'Категории',
  Catalan: 'Categories',
  Chinese: '类别',
  Croatian: 'Kategorije',
  Czech: 'Kategorie',
  Danish: 'Kategorier',
  Dutch: 'Categorieën',
  English: 'Categories',
  Estonian: 'Kategooriad',
  Finnish: 'Luokat',
  French: 'Catégories',
  Galician: 'Categorías',
  German: 'Kategorien',
  Greek: 'Κατηγορίες',
  Hebrew: 'קטגוריות',
  Hindi: 'श्रेणियाँ',
  Hungarian: 'Kategóriák',
  Icelandic: 'Flokkar',
  Italian: 'Categorie',
  Japanese: 'カテゴリー',
  Kannada: 'ವರ್ಗಗಳು',
  Kazakh: 'Санаттар',
  Korean: '카테고리',
  Macedonian: 'Категории',
  Malay: 'Kategori',
  Maori: 'Kāwai',
  Marathi: 'वर्ग',
  Punjabi: 'ਸ਼੍ਰੇਣੀਆਂ',
  Persian: 'دسته بندی ها',
  Polish: 'Kategorie',
  Portuguese: 'Categorias',
  Romanian: 'Categorii',
  Russian: 'Категории',
  Serbian: 'Категорије',
  Slovak: 'Kategórie',
  Slovenian: 'Kategorije',
  Spanish: 'Categorías',
  Swahili: 'Jamii',
  Swedish: 'Kategorier',
  Tamil: 'வகைகள்',
  Thai: 'หมวดหมู่',
  Turkish: 'Kategoriler',
  Ukrainian: 'Категорії',
  Urdu: 'زمرے',
  Vietnamese: 'Danh mục',
  Welsh: 'Categorïau',
};

export const translateNoDataAll: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om hierdie afdeling te genereer nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لإنشاء هذا القسم.',
  Armenian: 'Ներողության համար բավարար տվյալներ չկան այս բաժինը գեներացնելու համար:',
  Azerbaijani: 'Təəssüf ki, bu bölməni yaratmaq üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных для генерацыі гэтага раздзелу.',
  Bosnian: 'Nažalost, nema dovoljno podataka za generisanje ove sekcije.',
  Bulgarian: 'За съжаление, няма достатъчно данни, за да се генерира този раздел.',
  Catalan: 'Desafortunadament, no hi ha prou dades per generar aquesta secció.',
  Chinese: '很抱歉，没有足够的数据来生成此部分。',
  Croatian: 'Nažalost, nema dovoljno podataka za generiranje ovog odjeljka.',
  Czech: 'Omlouváme se, ale k vytvoření této sekce není dostatek dat.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at generere denne sektion.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om deze sectie te genereren.',
  English: 'Unfortunately there is not enough data to generate this section.',
  Estonian: 'Kahjuks pole selle jaotise genereerimiseks piisavalt andmeid.',
  Finnish: 'Valitettavasti tähän osioon ei ole riittävästi tietoja.',
  French: 'Malheureusement, il n\'y a pas assez de données pour générer cette section.',
  Galician: 'Desafortunadamente, non hai suficientes datos para xerar esta sección.',
  German: 'Leider liegen nicht genügend Daten vor, um diesen Abschnitt zu generieren.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να δημιουργηθεί αυτή η ενότητα.',
  Hebrew: 'לצערנו אין מספיק נתונים כדי ליצור את המקטע הזה.',
  Hindi: 'दुर्भाग्यवश इस खंड को उत्पन्न करने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnos nincs elég adat ehhez a szakaszhoz.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að búa til þessa hluta.',
  Italian: 'Purtroppo non ci sono abbastanza dati per generare questa sezione.',
  Japanese: '残念ながら、このセクションを生成するのに十分なデータがありません。',
  Kannada: 'ಅದುಕ್ಕೆ ಪ್ರಮಾಣ ಮಾಹಿತಿ ಸಾಕಷ್ಟು ಇಲ್ಲ ದುರದೃಷ್ಟವಶದಿಂದ ಈ ವಿಭಾಗವನ್ನು ರಚಿಸಲು.',
  Kazakh: 'Кешірім, мұнда бұрын болмаса да, мәліметтерді жеткізу үшін дәл мәліметтер жоқ.',
  Korean: '이 부분을 생성할만한 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за генерирање на оваа секција.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menghasilkan bahagian ini.',
  Maori: 'Aroha mai, kāore he ōrite mō te tohutoro i tenei wāhanga.',
  Marathi: 'दुर्दैवाने ह्या विभागाचे रचना करण्यासाठी पुरेसे डेटा नाही.',
  Punjabi: 'ਅਫਸੋਸ, ਇਸ ਖੰਡ ਨੂੰ ਤਿਆਰ ਕਰਨ ਲਈ ਪ੍ਰਯੋਗ ਡਾਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای ایجاد این بخش موجود نیست.',
  Polish: 'Niestety nie ma wystarczająco danych, aby wygenerować tę sekcję.',
  Portuguese: 'Infelizmente, não há dados suficientes para gerar esta seção.',
  Romanian: 'Din păcate, nu există suficiente date pentru a genera această secțiune.',
  Russian: 'К сожалению, недостаточно данных для генерации этого раздела.',
  Serbian: 'Нажалост, нема довољно података за генерисање ове секције.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na generovanie tejto časti.',
  Slovenian: 'Na žalost ni dovolj podatkov za generiranje tega odseka.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para generar esta sección.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha ya kuzalisha sehemu hii.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att generera den här sektionen.',
  Tamil: 'இந்த பிரிப்பானை உருவாக்க போது போதுமான தரவு இல்லையாம்.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะสร้างส่วนนี้',
  Turkish: 'Maalesef, bu bölümü oluşturmak için yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних для генерації цього розділу.',
  Urdu: 'افسوس کہ اس حصے کو تشکیل دینے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để tạo ra phần này.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i greu’r adran hon.',
};

export const translateNoSummaryData: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om \'n opsomming te genereer nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لإنشاء ملخص.',
  Armenian: 'Ներողության համար բավարար տվյալներ չկան այս ամփոփ գրառումը ստեղծելու համար:',
  Azerbaijani: 'Təəssüf ki, bir özət yaratmaq üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных для стварэння агляду.',
  Bosnian: 'Nažalost, nema dovoljno podataka za generisanje sažetka.',
  Bulgarian: 'За съжаление, няма достатъчно данни, за да се създаде обобщение.',
  Catalan: 'Desafortunadament, no hi ha prou dades per generar un resum.',
  Chinese: '很抱歉，没有足够的数据来生成摘要。',
  Croatian: 'Nažalost, nema dovoljno podataka za generiranje sažetka.',
  Czech: 'Omlouváme se, ale k vytvoření souhrnu není dostatek dat.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at generere en opsummering.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om een samenvatting te genereren.',
  English: 'Unfortunately there is not enough data to generate a summary.',
  Estonian: 'Kahjuks pole kokkuvõtte koostamiseks piisavalt andmeid.',
  Finnish: 'Valitettavasti tähän yhteenvetoon ei ole riittävästi tietoja.',
  French: 'Malheureusement, il n\'y a pas assez de données pour générer un résumé.',
  Galician: 'Desafortunadamente, non hai suficientes datos para xerar un resumo.',
  German: 'Leider liegen nicht genügend Daten vor, um eine Zusammenfassung zu generieren.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να δημιουργηθεί μια περίληψη.',
  Hebrew: 'לצערנו אין מספיק נתונים כדי ליצור תקציר.',
  Hindi: 'दुर्भाग्यवश एक सारांश तैयार करने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnos nincs elég adat ehhez az összefoglalóhoz.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að búa til yfirlit.',
  Italian: 'Purtroppo non ci sono abbastanza dati per generare un riassunto.',
  Japanese: '残念ながら、この要約を生成するのに十分なデータがありません。',
  Kannada: 'ಅದುಕ್ಕೆ ಪ್ರಮಾಣ ಸಾರಾಂಶಕ್ಕಾಗಿ ಸಾಕಷ್ಟು ಡೇಟಾ ಇಲ್ಲ.',
  Kazakh: 'Кешірім, көрсеткі үшін дәл мәліметтер жоқ.',
  Korean: '이 요약을 생성할만한 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за генерирање на сажетокот.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menghasilkan ringkasan.',
  Maori: 'Aroha mai, kāore he ōrite mō te tohutoro.',
  Marathi: 'दुर्दैवाने सारांश तयार करण्यासाठी पुरेसे डेटा नाही.',
  Punjabi: 'ਅਫਸੋਸ, ਇੱਕ ਸਾਰਾਂਸ਼ ਤਿਆਰ ਕਰਨ ਲਈ ਪ੍ਰਯੋਗ ਡਾਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای ایجاد خلاصه موجود نیست.',
  Polish: 'Niestety nie ma wystarczająco danych, aby wygenerować podsumowanie.',
  Portuguese: 'Infelizmente, não há dados suficientes para gerar um resumo.',
  Romanian: 'Din păcate, nu există suficiente date pentru a genera un rezumat.',
  Russian: 'К сожалению, недостаточно данных для создания обзора.',
  Serbian: 'Нажалост, нема довољно података за генерисање резимеа.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na generovanie súhrnu.',
  Slovenian: 'Na žalost ni dovolj podatkov za generiranje povzetka.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para generar un resumen.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha ya kuzalisha muhtasari.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att generera en sammanfattning.',
  Tamil: 'இந்த சுருக்கம் உருவாக்க போது போதுமான தரவு இல்லை.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะสร้างสรุป',
  Turkish: 'Maalesef, bu özet için yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних для створення огляду.',
  Urdu: 'افسوس کہ ایک خلاصہ تیار کرنے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để tạo ra bản tóm tắt.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i greu crynodeb.',
};

export const translateNoKeyThemesData: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om \'n lys van sleuteltemas te genereer nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لإنشاء قائمة بالموضوعات الرئيسية.',
  Armenian: 'Ներողության համար բավարար տվյալներ չկան հիմնական թեմաների ցանկ գրառումը ստեղծելու համար:',
  Azerbaijani: 'Təəssüf ki, əsas mövzuların siyahısını yaratmaq üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных для стварэння спісу ключавых тэм.',
  Bosnian: 'Nažalost, nema dovoljno podataka za generiranje liste ključnih tema.',
  Bulgarian: 'За съжаление, няма достатъчно данни, за да се създаде списък на ключови теми.',
  Catalan: 'Desafortunadament, no hi ha prou dades per generar una llista de temes clau.',
  Chinese: '很抱歉，没有足够的数据来生成关键主题列表。',
  Croatian: 'Nažalost, nema dovoljno podataka za generiranje liste ključnih tema.',
  Czech: 'Omlouváme se, ale k vytvoření seznamu klíčových témat není dostatek dat.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at generere en liste over nøgleemner.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om een lijst van sleutelthema\'s te genereren.',
  English: 'Unfortunately there is not enough data to generate a list of key themes.',
  Estonian: 'Kahjuks pole piisavalt andmeid põhiteemade loendi koostamiseks.',
  Finnish: 'Valitettavasti avainteemojen luettelon laatimiseen ei ole riittävästi tietoja.',
  French: 'Malheureusement, il n\'y a pas assez de données pour générer une liste des thèmes clés.',
  Galician: 'Desafortunadamente, non hai suficientes datos para xerar unha lista de temas clave.',
  German: 'Leider liegen nicht genügend Daten vor, um eine Liste der Schlüsselthemen zu generieren.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να δημιουργηθεί μια λίστα με τα κύρια θέματα.',
  Hebrew: 'לצערנו אין מספיק נתונים כדי ליצור רשימה של נושאים מרכזיים.',
  Hindi: 'दुर्भाग्यवश एक प्रमुख विषयों की सूची तैयार करने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnos nincs elég adat ehhez az összefoglalóhoz.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að búa til yfirlit.',
  Italian: 'Purtroppo non ci sono abbastanza dati per generare un riassunto.',
  Japanese: '残念ながら、この要約を生成するのに十分なデータがありません。',
  Kannada: 'ಅದುಕ್ಕೆ ಪ್ರಮುಖ ವಿಷಯಗಳ ಪಟ್ಟಿಯನ್ನು ರಚಿಸಲು ಸಾಕಷ್ಟು ಡೇಟಾ ಇಲ್ಲ.',
  Kazakh: 'Кешірім, кімімді ұғымдардың тізімін жасау үшін дәл мәліметтер жоқ.',
  Korean: '이 요약을 생성할만한 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за генерирање на списокот на клучни теми.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menghasilkan senarai tema utama.',
  Maori: 'Aroha mai, kāore he ōrite mō te tohutoro.',
  Marathi: 'दुर्दैवाने प्रमुख विषयांची यादी तयार करण्यासाठी पुरेसे डेटा नाही.',
  Punjabi: 'ਅਫਸੋਸ, ਇੱਕ ਮੁੱਖ ਮੁਦਾਂ ਦੀ ਸੂਚੀ ਤਿਆਰ ਕਰਨ ਲਈ ਪ੍ਰਯੋਗ ਡਾਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای ایجاد فهرست موضوعات کلیدی موجود نیست.',
  Polish: 'Niestety nie ma wystarczająco danych, aby wygenerować listę kluczowych tematów.',
  Portuguese: 'Infelizmente, não há dados suficientes para gerar uma lista de temas principais.',
  Romanian: 'Din păcate, nu există suficiente date pentru a genera o listă de teme cheie.',
  Russian: 'К сожалению, недостаточно данных для создания списка ключевых тем.',
  Serbian: 'Нажалост, нема довољно података за генерисање списака кључних тема.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na generovanie zoznamu kľúčových tém.',
  Slovenian: 'Na žalost ni dovolj podatkov za generiranje seznama ključnih tem.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para generar una lista de temas clave.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha ya kuzalisha orodha ya mada muhimu.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att generera en lista över nyckelämnen.',
  Tamil: 'இந்த சுருக்கம் உருவாக்க போது போதுமான தரவு இல்லை.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะสร้างรายการของหัวข้อสำคัญ',
  Turkish: 'Maalesef, anahtar konuların bir listesini oluşturmak için yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних для створення списку ключових тем.',
  Urdu: 'افسوس کہ اہم موضوعات کی فہرست تیار کرنے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để tạo ra danh sách các chủ đề chính.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i greu rhestr o themâu allweddol.',
};

export const translateNoQuotesData: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om interessante aanhalings uit die onderhoude te wys nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لعرض الاقتباسات المثيرة من المقابلات.',
  Armenian: 'Ներողության համար բավարար տվյալներ չկան հետաքրքրող մեկնաբանությունները ցուցադրելու համար:',
  Azerbaijani: 'Təəssüf ki, maraqlı sitatları müsahibələrdən göstərmək üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных, каб паказаць вам цікавыя цытаты з інтэрв\'ю.',
  Bosnian: 'Nažalost, nema dovoljno podataka da vam se prikažu zanimljive izjave iz intervjua.',
  Bulgarian: 'За съжаление, няма достатъчно данни, за да ви покажем интересни цитати от интервютата.',
  Catalan: 'Desafortunadament, no hi ha prou dades per mostrar-vos cites interessants de les entrevistes.',
  Chinese: '很抱歉，没有足够的数据来显示您在面试中的有趣引用。',
  Croatian: 'Nažalost, nema dovoljno podataka da vam se prikažu zanimljive izjave iz intervjua.',
  Czech: 'Omlouváme se, ale nemáme dostatek dat, abychom vám mohli ukázat zajímavé citáty z rozhovorů.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at vise dig interessante citater fra interviews.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om u interessante citaten uit de interviews te tonen.',
  English: 'Unfortunately there is not enough data to show you quotes from the interviews.',
  Estonian: 'Kahjuks pole piisavalt andmeid, et näidata teile huvitavaid tsitaate intervjuudest.',
  Finnish: 'Valitettavasti haastatteluista ei ole tarpeeksi tietoa näyttääkseni sinulle mielenkiintoisia lainauksia.',
  French: 'Malheureusement, il n\'y a pas assez de données pour vous montrer des citations intéressantes des entretiens.',
  Galician: 'Desafortunadamente, non hai suficientes datos para amosarche citas interesantes das entrevistas.',
  German: 'Leider liegen nicht genügend Daten vor, um Ihnen interessante Zitate aus den Interviews zu zeigen.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να σας εμφανίσουμε ενδιαφέρουσες φράσεις από τις συνεντεύξεις.',
  Hebrew: 'לצערנו אין מספיק נתונים כדי להציג לכם ציטוטים מעניינים מהראיונות.',
  Hindi: 'दुर्भाग्यवश, साक्षात्कारों से आपको रोचक उद्धरण दिखाने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnálatos módon nincs elég adat ahhoz, hogy érdekes idézeteket mutassunk Önnek az interjúkból.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að sýna þér áhugaverðar tilvitnanir úr viðtölum.',
  Italian: 'Purtroppo non ci sono abbastanza dati per mostrarti citazioni interessanti dagli interviste.',
  Japanese: '残念ながら、面接から興味深い引用を表示するための十分なデータがありません。',
  Kannada: 'ದುರ್ಭಾಗ್ಯಕ್ಕಾಗಿ, ಸಂಭಾಷಣೆಗಳಿಂದ ನಿಮಗೆ ಆಸಕ್ತಿಯ ಉಲ್ಲೇಖಗಳನ್ನು ತೋರಿಸಲು ಸಾಕಷ್ಟು ಡೇಟಾ ಇಲ್ಲ.',
  Kazakh: 'Кешірім, интервьюлардан сізге қызықты цитаталарды көрсетуге үшін дәл мәліметтер жоқ.',
  Korean: '죄송하지만 인터뷰에서 흥미로운 인용구를 보여줄 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за прикажување на интересни цитати од интервјуата.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menunjukkan petikan menarik dari temu ramah.',
  Maori: 'Aroha mai, kāore he ōrite mō te tohutoro.',
  Marathi: 'दुर्दैवाने, माहितीस रोखण्यासाठी पर्याप्त डेटा नाहीत.',
  Punjabi: 'ਅਫਸੋਸ, ਇੱਕ ਮੁੱਖ ਵਿਚਾਰਾਂ ਦੇ ਉਤਾਰਨ ਲਈ ਕਾਫ਼ੀ ਡੇਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای نمایش نقل قول های جالب از مصاحبه ها وجود ندارد.',
  Polish: 'Niestety nie ma wystarczająco danych, aby pokazać Ci interesujące cytaty z wywiadów.',
  Portuguese: 'Infelizmente, não há dados suficientes para mostrar citações interessantes das entrevistas.',
  Romanian: 'Din păcate, nu există suficiente date pentru a vă arăta citate interesante din interviuri.',
  Russian: 'К сожалению, недостаточно данных для отображения вам интересных цитат из интервью.',
  Serbian: 'Нажалост, нема довољно података да вам се прикажу занимљиве изјаве из интервјуа.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na zobrazenie zaujímavých citátov z rozhovorov.',
  Slovenian: 'Na žalost ni dovolj podatkov, da vam pokažemo zanimive citate iz intervjujev.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para mostrarle citas interesantes de las entrevistas.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha kuonyesha quotes za kuvutia kutoka kwa mahojiano.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att visa dig intressanta citat från intervjuerna.',
  Tamil: 'இந்த சுருக்கம் உருவாக்க போது போதுமான தரவு இல்லை.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะแสดงคำพูดที่น่าสนใจจากการสัมภาษณ์',
  Turkish: 'Maalesef, röportajlardan ilginç alıntıları size gösterecek yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних, щоб показати вам цікаві цитати з інтерв\'ю.',
  Urdu: 'افسوس، مقابلوں سے دلچسپ حوالے دکھانے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để hiển thị các câu trích dẫn thú vị từ các cuộc phỏng vấn.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i ddangos dyfyniadau diddorol o\'r cyfweliadau.',
};

export const translateNoOpportunitiesData: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om geleenthede uit jou onderhoude te genereer nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لتوليد الفرص من مقابلاتك.',
  Armenian: 'Ներողության համար բավարար տվյալներ չկան խնդրերը ձեր հարցաթերթերից գեներացնելու համար:',
  Azerbaijani: 'Təəssüf ki, müsahibələrinizdən imkanlar yaradılması üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных, каб стварыць магчымасці з васьмі інтэрв\'ю.',
  Bosnian: 'Nažalost, nema dovoljno podataka za generiranje prilika iz vaših intervjua.',
  Bulgarian: 'За съжаление, няма достатъчно данни за генериране на възможности от вашите интервюта.',
  Catalan: 'Desafortunadament, no hi ha prou dades per generar oportunitats a partir dels teus entrevistes.',
  Chinese: '很抱歉，没有足够的数据从你的面试中生成机会。',
  Croatian: 'Nažalost, nema dovoljno podataka za generiranje prilika iz vaših intervjua.',
  Czech: 'Omlouváme se, ale nemáme dostatek dat na generování příležitostí z vašich rozhovorů.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at generere muligheder fra dine interviews.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om kansen te genereren uit uw interviews.',
  English: 'Unfortunately there is not enough data to generate opportunities from your interviews.',
  Estonian: 'Kahjuks pole piisavalt andmeid teie intervjuudest võimaluste genereerimiseks.',
  Finnish: 'Valitettavasti haastatteluista ei ole tarpeeksi tietoja mahdollisuuksien luomiseksi.',
  French: 'Malheureusement, il n\'y a pas assez de données pour générer des opportunités à partir de vos entretiens.',
  Galician: 'Desafortunadamente, non hai suficientes datos para xerar oportunidades das súas entrevistas.',
  German: 'Leider liegen nicht genügend Daten vor, um Möglichkeiten aus Ihren Interviews zu generieren.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να δημιουργήσουν ευκαιρίες από τις συνεντεύξεις σας.',
  Hebrew: 'לצערנו אין מספיק נתונים ליצירת הזדמנויות מהראיונות שלך.',
  Hindi: 'दुर्भाग्यवश, आपके साक्षात्कारों से अवसर उत्पन्न करने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnos nincs elég adat a lehetőségek generálásához az interjúidból.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að búa til möguleika úr viðtölum þínum.',
  Italian: 'Purtroppo non ci sono abbastanza dati per generare opportunità dai tuoi colloqui.',
  Japanese: '残念ながら、あなたのインタビューから機会を生成するのに十分なデータがありません。',
  Kannada: 'ದುರ್ಭಾಗ್ಯವಶಃ, ನಿಮ್ಮ ಸಾಕ್ಷಾತ್ಕಾರಗಳಿಂದ ಅವಕಾಶಗಳನ್ನು ರಚಿಸಲು ಸಾಕಷ್ಟು ಡೇಟಾ ಇಲ್ಲ.',
  Kazakh: 'Кешірім, сіздің интервьюларыңыздан мүмкіндіктерді жасау үшін дәл мәліметтер жоқ.',
  Korean: '죄송하지만 인터뷰에서 기회를 생성할 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за генерирање на можностите од вашите интервјуа.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menghasilkan peluang dari temu ramah anda.',
  Maori: 'Aroha mai, kāore he ōrite mō te tohutoro.',
  Marathi: 'दुर्दैवाने, आपल्या साक्षात्कारांमधून संधी सिद्ध करण्यासाठी पर्याप्त डेटा नाही.',
  Punjabi: 'ਅਫਸੋਸ, ਤੁਹਾਡੇ ਇੰਟਰਵਿਊਜ਼ ਤੋਂ ਮੌਕੇ ਬਣਾਉਣ ਲਈ ਕਾਫੀ ਡਾਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای تولید فرصت ها از مصاحبه های شما وجود ندارد.',
  Polish: 'Niestety nie ma wystarczających danych do generowania możliwości z twoich wywiadów.',
  Portuguese: 'Infelizmente, não há dados suficientes para gerar oportunidades a partir de suas entrevistas.',
  Romanian: 'Din păcate, nu există suficiente date pentru a genera oportunități din interviurile tale.',
  Russian: 'К сожалению, недостаточно данных для создания возможностей из ваших интервью.',
  Serbian: 'Нажалост, нема довољно података за генерисање прилика из ваших интервјуа.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na generovanie príležitostí z vašich rozhovorov.',
  Slovenian: 'Na žalost ni dovolj podatkov za generiranje priložnosti iz vaših intervjujev.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para generar oportunidades a partir de tus entrevistas.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha kuzalisha fursa kutoka kwa mahojiano yako.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att generera möjligheter från dina intervjuer.',
  Tamil: 'இந்த சுருக்கம் உருவாக்க போது போதுமான தரவு இல்லை.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะสร้างโอกาสจากการสัมภาษณ์ของคุณ',
  Turkish: 'Maalesef, görüşmelerinizden fırsatlar üretmek için yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних, щоб створити можливості з вашого інтерв\'ю.',
  Urdu: 'افسوس، آپ کے مصاحبوں سے مواقع پیدا کرنے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để tạo ra cơ hội từ cuộc phỏng vấn của bạn.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i greu cyfleoedd o\'ch cyfweliadau.',
};

export const translateQuestions: Translation = {
  Afrikaans: 'Vrae',
  Arabic: 'أسئلة',
  Armenian: 'Հարցեր',
  Azerbaijani: 'Sual',
  Belarusian: 'Пытанні',
  Bosnian: 'Pitanja',
  Bulgarian: 'Въпроси',
  Catalan: 'Preguntes',
  Chinese: '问题',
  Croatian: 'Pitanja',
  Czech: 'Otázky',
  Danish: 'Spørgsmål',
  Dutch: 'Vragen',
  English: 'Questions',
  Estonian: 'Küsimused',
  Finnish: 'Kysymykset',
  French: 'Questions',
  Galician: 'Preguntas',
  German: 'Fragen',
  Greek: 'Ερωτήσεις',
  Hebrew: 'שאלות',
  Hindi: 'प्रश्न',
  Hungarian: 'Kérdések',
  Icelandic: 'Spurningar',
  Italian: 'Domande',
  Japanese: '質問',
  Kannada: 'ಪ್ರಶ್ನೆಗಳು',
  Kazakh: 'Сұрақтар',
  Korean: '질문',
  Macedonian: 'Прашања',
  Malay: 'Soalan',
  Maori: 'Pātai',
  Marathi: 'प्रश्न',
  Punjabi: 'ਪ੍ਰਸ਼ਨ',
  Persian: 'سوالات',
  Polish: 'Pytania',
  Portuguese: 'Perguntas',
  Romanian: 'Întrebări',
  Russian: 'Вопросы',
  Serbian: 'Питања',
  Slovak: 'Otázky',
  Slovenian: 'Vprašanja',
  Spanish: 'Preguntas',
  Swahili: 'Maswali',
  Swedish: 'Frågor',
  Tamil: 'கேள்விகள்',
  Thai: 'คำถาม',
  Turkish: 'Sorular',
  Ukrainian: 'Запитання',
  Urdu: 'سوالات',
  Vietnamese: 'Câu hỏi',
  Welsh: 'Cwestiynau',
};

export const translateNoQuestionsData: Translation = {
  Afrikaans: 'Ongelukkig is daar nie genoeg data om vrae te genereer nie.',
  Arabic: 'للأسف ليس هناك ما يكفي من البيانات لإنشاء أسئلة.',
  Armenian: 'Ներողություն, բավարար տվյալներ չկան հարցեր ստեղծելու համար:',
  Azerbaijani: 'Təəssüf ki, suallar yaratmaq üçün kifayət qədər məlumat yoxdur.',
  Belarusian: 'На жаль, недастаткова дадзеных для стварэння пытанняў.',
  Bosnian: 'Nažalost, nema dovoljno podataka za generisanje pitanja.',
  Bulgarian: 'За съжаление, няма достатъчно данни за генериране на въпроси.',
  Catalan: 'Desafortunadament, no hi ha prou dades per generar preguntes.',
  Chinese: '很抱歉，没有足够的数据来生成问题。',
  Croatian: 'Nažalost, nema dovoljno podataka za generiranje pitanja.',
  Czech: 'Omlouváme se, ale k vytvoření otázek není dostatek dat.',
  Danish: 'Desværre er der ikke tilstrækkelige data til at generere spørgsmål.',
  Dutch: 'Helaas zijn er niet genoeg gegevens om vragen te genereren.',
  English: 'Unfortunately there is not enough data to generate questions.',
  Estonian: 'Kahjuks pole küsimuste koostamiseks piisavalt andmeid.',
  Finnish: 'Valitettavasti kysymysten luomiseen ei ole riittävästi tietoja.',
  French: "Malheureusement, il n'y a pas assez de données pour générer des questions.",
  Galician: 'Desafortunadamente, non hai suficientes datos para xerar preguntas.',
  German: 'Leider liegen nicht genügend Daten vor, um Fragen zu generieren.',
  Greek: 'Δυστυχώς δεν υπάρχουν αρκετά δεδομένα για να δημιουργηθούν ερωτήσεις.',
  Hebrew: 'לצערנו אין מספיק נתונים כדי ליצור שאלות.',
  Hindi: 'दुर्भाग्यवश प्रश्न तैयार करने के लिए पर्याप्त डेटा नहीं है।',
  Hungarian: 'Sajnos nincs elég adat a kérdések generálásához.',
  Icelandic: 'Því miður eru ekki nægjanleg gögn til að búa til spurningar.',
  Italian: 'Purtroppo non ci sono abbastanza dati per generare domande.',
  Japanese: '残念ながら、質問を生成するのに十分なデータがありません。',
  Kannada: 'ದುರದೃಷ್ಟವಶಾತ್ ಪ್ರಶ್ನೆಗಳನ್ನು ರಚಿಸಲು ಸಾಕಷ್ಟು ಡೇಟಾ ಇಲ್ಲ.',
  Kazakh: 'Өкінішке орай, сұрақтар жасау үшін жеткілікті деректер жоқ.',
  Korean: '질문을 생성할만한 데이터가 충분하지 않습니다.',
  Macedonian: 'За жал, нема доволно податоци за генерирање прашања.',
  Malay: 'Malangnya, tidak ada data yang mencukupi untuk menghasilkan soalan.',
  Maori: 'Aroha mai, kāore he ōrite mō ngā pātai.',
  Marathi: 'दुर्दैवाने प्रश्न तयार करण्यासाठी पुरेसे डेटा नाही.',
  Punjabi: 'ਅਫਸੋਸ, ਸਵਾਲਾਂ ਨੂੰ ਤਿਆਰ ਕਰਨ ਲਈ ਪ੍ਰਯਾਪਤ ਡਾਟਾ ਨਹੀਂ ਹੈ।',
  Persian: 'متاسفانه داده کافی برای ایجاد سوالات موجود نیست.',
  Polish: 'Niestety nie ma wystarczająco danych, aby wygenerować pytania.',
  Portuguese: 'Infelizmente, não há dados suficientes para gerar perguntas.',
  Romanian: 'Din păcate, nu există suficiente date pentru a genera întrebări.',
  Russian: 'К сожалению, недостаточно данных для создания вопросов.',
  Serbian: 'Нажалост, нема довољно података за генерисање питања.',
  Slovak: 'Bohužiaľ, nie sú k dispozícii dostatočné údaje na generovanie otázok.',
  Slovenian: 'Na žalost ni dovolj podatkov za generiranje vprašanj.',
  Spanish: 'Desafortunadamente, no hay suficientes datos para generar preguntas.',
  Swahili: 'Kwa bahati mbaya hakuna data ya kutosha ya kuzalisha maswali.',
  Swedish: 'Tyvärr finns det inte tillräckligt med data för att generera frågor.',
  Tamil: 'இந்த கேள்விகளை உருவாக்க போதுமான தரவு இல்லை.',
  Thai: 'ขอโทษเราไม่มีข้อมูลเพียงพอที่จะสร้างคำถาม',
  Turkish: 'Maalesef, bu sorular için yeterli veri yok.',
  Ukrainian: 'На жаль, недостатньо даних для створення питань.',
  Urdu: 'افسوس کہ سوالات تیار کرنے کے لئے کافی ڈیٹا نہیں ہے۔',
  Vietnamese: 'Rất tiếc, không có đủ dữ liệu để tạo ra câu hỏi.',
  Welsh: 'Yn anffodus, nid oes digon o ddata i greu cwestiynau.',
};
