import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useReactMediaRecorder } from "../lib/react-media-recorder";
import useChatStore from '../store';
import useSendMessage from './useSendMessage';

export const useVideoRecorder = () => {
  const { addMessageAndStartCreateAsistant, currentProjectToken } = useChatStore((state) => state);
  const sendMessage = useSendMessage()
  const [video, setVideo] = useState<Blob | null>(null);
  const [isVideoRecordingPressed, setIsVideoRecordingPressed] = useState(false);
  const [showVideoRecordingModal, setShowVideoRecordingModal] = useState(false);
  const [mediaStreamVideo, setMediaStreamVideo] = useState<MediaStream | null>(null);
  const [isSending, setIsSending] = useState(false);

  const stopMediaTracksVideo = useCallback((stream: MediaStream | null) => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
    }
  }, []);

  const handleRequestPermissionsVideo = useCallback(async () => {
    try {
      if (!navigator?.mediaDevices?.getUserMedia) {
        return toast.error("Unfortunately, your browser lacks media recording support.");
      }

      if (!mediaStreamVideo) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setMediaStreamVideo(stream);
      }
      setIsVideoRecordingPressed(true);
    } catch {
      setIsVideoRecordingPressed(false);
      toast.info("Enable video and audio access in your browser settings to send video messages.");
    }
  }, [mediaStreamVideo]);

  const {
    status: statusVideo,
    startRecording: startVideoRecording,
    stopRecording: stopVideoRecording,
    pauseRecording: pauseVideoRecording,
    resumeRecording: resumeVideoRecording,
    previewStream,
    clearBlobUrl,
    mediaBlobUrl
  } = useReactMediaRecorder({
    video: true,
    audio: true,
    stopStreamsOnStop: true,
    onStop: async (_, blob) => {
      setVideo(blob);
      stopMediaTracksVideo(previewStream);
    },
  });

  const isVideoRecording = statusVideo === 'recording';
  const isVideoRecordingActive = isVideoRecording && isVideoRecordingPressed;

  const handleStartVideoRecording = useCallback(async () => {
    if (!mediaStreamVideo) {
      await handleRequestPermissionsVideo();
    }
    setVideo(null);
    setShowVideoRecordingModal(true);
    startVideoRecording();
  }, [mediaStreamVideo, handleRequestPermissionsVideo]);

  const handleStopVideoRecording = useCallback(() => {
    stopVideoRecording();
    setVideo(null);
    clearBlobUrl();
    stopMediaTracksVideo(previewStream);
    stopMediaTracksVideo(mediaStreamVideo);
    setMediaStreamVideo(null);
  }, [mediaStreamVideo, previewStream, stopVideoRecording, stopMediaTracksVideo]);

  const handlePauseVideoRecording = () => {
    pauseVideoRecording();
  }

  const handleResumeVideoRecording = () => {
    resumeVideoRecording();
  }

  const handleExitVideoRecording = useCallback(() => {
    setIsVideoRecordingPressed(false);
    stopVideoRecording();
    setVideo(null);
    clearBlobUrl();
    stopMediaTracksVideo(previewStream);
    stopMediaTracksVideo(mediaStreamVideo);
    setShowVideoRecordingModal(false);
    setMediaStreamVideo(null);
  }, [mediaStreamVideo, previewStream, stopVideoRecording, stopMediaTracksVideo]);

  const sendVideoMessage = useCallback(
    async (videoBlob: Blob) => {
      try {
        await sendMessage({
          file: videoBlob,
          text: '',
          onError: console.error,
          projectToken: currentProjectToken,
        });

        addMessageAndStartCreateAsistant({
          _id: Math.random().toString(),
          role: 'user',
          text: '',
          loading: false,
          data: [{ type: 'video', url: URL.createObjectURL(videoBlob), isUser: true }],
          created_at: new Date().toISOString(),
        });
      } catch {
        toast.error('Error sending video message.');
      }
    },
    [sendMessage, addMessageAndStartCreateAsistant, currentProjectToken]
  );

  const handleSubmitVideo = useCallback(async () => {
    if (!video || isSending) return;
    setIsSending(true);

    try {
      await sendVideoMessage(video);
      setVideo(null);
      setShowVideoRecordingModal(false);
      setIsVideoRecordingPressed(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSending(false);
    }
  }, [video, sendVideoMessage]);

  return {
    statusVideo,
    previewStream,
    isVideoRecordingActive,
    showVideoRecordingModal,
    handleStartVideoRecording,
    handleStopVideoRecording,
    handleExitVideoRecording,
    handlePauseVideoRecording,
    handleResumeVideoRecording,
    resumeVideoRecording,
    handleSubmitVideo,
    mediaBlobUrl,
    isVideoRecordingPressed
  };
};
