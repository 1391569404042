import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { EProjectStatus } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import { translateDivergentViewsAndOutliers, translateKeyThemes, translateNoKeyThemesData, translateNoOpportunitiesData, translateNoSummaryData, translateOpportunities, translatePatternsAndTrends, translateSummary } from '@/pages/projects/single-project/Content/project_results/resultsPageTranslations';
import useQuestionsLoadingStore from '@/store/QuestionsLoadingStore';
import useReportStore from '@/store/ReportStore';
import PaperAirplaneIcon from '../icons/PaperAirplaneIcon';
import SummarySkeletonLoader from '../loaders/SummarySkeletonLoader';
import { Button } from '../ui/button';
import Categories from './Categories';
import DataWithSteps from './DataWithSteps';
import QuestionAnswersTable from './QuestionAnswersTable';
import useCategoriesLoadingStore from '@/store/CategoriesLoadingStore';

interface IPerQuestionProps {
  isPendingReRun: boolean;
  isLoadingInshights: boolean;
  selectedQuestionInshights: IInsights;
  selectedQuestionTranscripts: ITranscripts[] | undefined;
  questionID: string | undefined;
  analysisLanguage: string;
  singleProjectData: IProjectProps;
  newConversationsCount: number;
  onReRun: () => void;
  isTranscriptsLoading: boolean;
  isRerunLoading: boolean;
}

interface ICategory {
  label: string;
  color: string;
  prompt: string;
}

interface ITranscripts {
  gender?: string;
  age?: number;
  started: string;
  conversation_id: string;
  questions: string;
  category: ICategory[];
}

interface IQuotes {
  id: string;
  quote: string;
}

interface IInsights {
  _id: string;
  summary: string;
  themes: IStepsData[];
  quotes: IQuotes[];
  opportunities: IStepsData[];
  patterns_and_trends: IStepsData[];
  divergent_views_and_outliers: IStepsData[];
}

interface IStepsData {
  title: string;
  body: string
}

function QuestionItem({ isPendingReRun, selectedQuestionInshights,
  isLoadingInshights, selectedQuestionTranscripts, questionID, analysisLanguage,
  singleProjectData, newConversationsCount, onReRun, isTranscriptsLoading, isRerunLoading }: IPerQuestionProps) {
  const { lang } = useTranslation();
  const { project_id } = useParams();
  const { currentTheme } = useTheme();

  const questionData = useReportStore((state) => state.questionData);
  const setQuestionData = useReportStore((state) => state.setQuestionData);
  const deleteQuestionData = useReportStore((state) => state.deleteQuestionData);

  const isQuestionDataSelected = useReportStore((state) => state.isQuestionDataSelected);
  const setIsQuestionDataSelected = useReportStore((state) => state.setIsQuestionDataSelected);

  const questionsLoading = useQuestionsLoadingStore((state) => state.questionsLoading);
  const categoriesLoading = useCategoriesLoadingStore((state) => state.categoriesLoading);

  const [isFocusedInsights, setIsFocusedInsights] = useState(true);
  const [isFocusedAnswers, setIsFocusedAnswers] = useState(false);

  const handleFocusInsights = () => {
    setIsFocusedInsights(true);
    setIsFocusedAnswers(false);
  };

  const handleFocusAnswers = () => {
    setIsFocusedAnswers(true);
    setIsFocusedInsights(false);
  };

  const tabStyleInsights = {
    backgroundColor: isFocusedInsights ? currentTheme?.primary : 'transparent',
    color: isFocusedInsights ? 'white' : 'black',
  };

  const tabStyleAnswers = {
    backgroundColor: isFocusedAnswers ? currentTheme?.primary : 'transparent',
    color: isFocusedAnswers ? 'white' : 'black',
  };

  const onReportSaveQuestion = () => {
    setIsQuestionDataSelected(!isQuestionDataSelected);
    if (!isQuestionDataSelected) {
      setQuestionData([
        ...questionData,
        { question: selectedQuestionInshights },
      ]);
    } else {
      deleteQuestionData(selectedQuestionInshights?._id);
    }
  };

  useEffect(() => {
    const itemFound = questionData.find((item) => item.question._id === selectedQuestionInshights?._id);
    if (itemFound) setIsQuestionDataSelected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestionInshights?._id]);

  const noEnoughInterviewData = selectedQuestionInshights?.summary?.length === 0 && selectedQuestionInshights?.themes?.length === 0
    && selectedQuestionInshights?.quotes?.length === 0
    && selectedQuestionInshights?.opportunities?.length === 0 && selectedQuestionInshights?.quotes?.length === 0;

  const isThemesAvailable = !!selectedQuestionInshights?.themes?.length;
  const isPatternsAvailable = !!selectedQuestionInshights?.patterns_and_trends?.length;
  const isOpportunitiesAvailable = !!selectedQuestionInshights?.opportunities?.length;
  const isDivergentViewsAvailable = !!selectedQuestionInshights?.divergent_views_and_outliers?.length;

  return (
    <Tabs defaultValue="insights" className="w-full">
      <TabsList className="w-fit rounded-xl">
        <TabsTrigger
          disabled={questionsLoading}
          style={tabStyleInsights}
          onFocus={handleFocusInsights}
          className="rounded-lg py-2 w-[350px] md900:w-[250px] md700:w-[200px] md600:w-[150px]"
          value="insights"
        >
          {lang.get('msg.insights')}
        </TabsTrigger>
        <TabsTrigger
          disabled={isLoadingInshights || questionsLoading || categoriesLoading}
          style={tabStyleAnswers}
          onFocus={handleFocusAnswers}
          className="rounded-lg py-2 w-[350px] md900:w-[250px] md700:w-[200px] md600:w-[150px]"
          value="transcripts"
        >
          {categoriesLoading ? (
            <IconLoader2
              style={{ color: currentTheme?.primary }}
              className="animate-spin"
              size={18}
            />
          ) : lang.get('msg.answers')}
        </TabsTrigger>
        {(!isPendingReRun && !isLoadingInshights && !questionsLoading) && (
          <div className="flex items-center justify-center w-5 h-5 ml-4 border p-0.5 mx-2 border-black rounded-full">
            <button
              onClick={() => {
                onReportSaveQuestion();
              }}
              type="button"
              style={{ backgroundColor: isQuestionDataSelected ? currentTheme?.primary : '#FFFFFF' }}
              className={cn('w-full h-full rounded-full')}
            >
            </button>
          </div>
        )}
        {(!isPendingReRun && !isLoadingInshights && !questionsLoading) && <p className="mr-4 text-sm whitespace-nowrap">Add to report</p>}
      </TabsList>

      {/* INSIGHTS CONTENT  */}
      <TabsContent value="insights" className="pb-10">
        {!noEnoughInterviewData ? (
          <div className="flex flex-col w-full gap-10 py-4 pl-1">
            {/* categories */}
            <div className="flex flex-col w-full">
              <Categories
                projectID={project_id}
                questionID={questionID}
                analysisLanguage={analysisLanguage}
              />
            </div>
            {/* summary */}
            <div className="flex flex-col w-full">
              <h3
                style={{ color: currentTheme?.primary }}
                className="!mb-4 text-lg font-bold epilogue-font"
              >
                {translateSummary[analysisLanguage] || translateSummary.English}
              </h3>
              {selectedQuestionInshights?.summary?.length !== 0 ? (
                <div className="">
                  {(isPendingReRun || isLoadingInshights || questionsLoading)
                    ? <SummarySkeletonLoader />
                    : (
                      <ReactMarkdown>
                        {selectedQuestionInshights?.summary}
                      </ReactMarkdown>
                    )}
                </div>
              ) : <p className="w-full text-left text-[15px]  mx-auto">{translateNoSummaryData[analysisLanguage] || translateNoSummaryData.English}</p>}
            </div>
            {/* key themes */}
            <div className="flex flex-col w-full">
              {/* Title Section */}
              {(!isThemesAvailable || !isPatternsAvailable) && (
                <h3 style={{ color: currentTheme?.primary }} className="!mb-4 text-lg font-bold epilogue-font">
                  {isThemesAvailable && (translateKeyThemes[analysisLanguage] || translateKeyThemes.English)}
                </h3>
              )}

              {isPatternsAvailable && (
                <h3 style={{ color: currentTheme?.primary }} className="!mb-4 text-lg font-bold epilogue-font">
                  {translatePatternsAndTrends[analysisLanguage] || translatePatternsAndTrends.English}
                </h3>
              )}

              {/* Data Section */}
              {isThemesAvailable && !isPatternsAvailable && (
                <DataWithSteps data={selectedQuestionInshights.themes} isLoading={isLoadingInshights || isPendingReRun || questionsLoading} />
              )}
              {isPatternsAvailable && (
                <DataWithSteps data={selectedQuestionInshights.patterns_and_trends} isLoading={isLoadingInshights || isPendingReRun || questionsLoading} />
              )}

              {/* No Data Message */}
              {!isThemesAvailable && !isPatternsAvailable && (
                <p className="w-full text-left text-[15px]  mx-auto">
                  {translateNoKeyThemesData[analysisLanguage] || translateNoKeyThemesData.English}
                </p>
              )}
            </div>

            {/* opportunities */}
            <div className="flex flex-col w-full">
              {/* Title Section */}
              {(!isOpportunitiesAvailable || !isDivergentViewsAvailable) && (
                <h3 style={{ color: currentTheme?.primary }} className="!mb-4 text-lg font-bold epilogue-font">
                  {isOpportunitiesAvailable && (translateOpportunities[analysisLanguage] || translateOpportunities.English)}
                </h3>
              )}

              {isDivergentViewsAvailable && (
                <h3 style={{ color: currentTheme?.primary }} className="!mb-4 text-lg font-bold epilogue-font">
                  {translateDivergentViewsAndOutliers[analysisLanguage] || translateDivergentViewsAndOutliers.English}
                </h3>
              )}

              {/* Data Section */}
              {isOpportunitiesAvailable && !isDivergentViewsAvailable && (
                <DataWithSteps data={selectedQuestionInshights.opportunities} isLoading={isLoadingInshights || isPendingReRun || questionsLoading} />
              )}
              {isDivergentViewsAvailable && (
                <DataWithSteps data={selectedQuestionInshights.divergent_views_and_outliers} isLoading={isLoadingInshights || isPendingReRun || questionsLoading} />
              )}

              {/* No Data Message */}
              {!isOpportunitiesAvailable && !isDivergentViewsAvailable && (
                <p className="w-full text-left text-[15px]  mx-auto">
                  {translateNoOpportunitiesData[analysisLanguage] || translateNoKeyThemesData.English}
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
            {singleProjectData?.status !== EProjectStatus.COMPLETED && (
              <Button
                variant="default"
                disabled={isPendingReRun || isRerunLoading}
                className="relative flex items-center gap-2 mt-5 font-bold px-7"
                onClick={onReRun}
              >
                {
                  (isPendingReRun || isRerunLoading)
                    ? <IconLoader2 className="animate-spin" size={20} />
                    : <PaperAirplaneIcon className="size-4" />
                }
                {isPendingReRun || isRerunLoading ? 'Running...' : 'Run'}
                {!isRerunLoading && newConversationsCount > 0 && (
                  <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                    {newConversationsCount}
                  </span>
                )}
              </Button>
            )}
          </div>
        )}
      </TabsContent>

      {/* TRANSCRIPTS CONTENT */}
      <TabsContent value="transcripts">
        <div className="w-full py-4">
          {!questionsLoading && !isTranscriptsLoading
            ? <QuestionAnswersTable questionID={questionID} selectedQuestionTranscripts={selectedQuestionTranscripts} />
            : (
              <div className="flex items-center justify-center w-full py-20 mx-auto">
                <IconLoader2 style={{ color: currentTheme?.primary }} className="animate-spin" size={35} />
              </div>
            )}
        </div>
      </TabsContent>
    </Tabs>
  );
}

export default QuestionItem;
