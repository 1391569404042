interface DurationProps {
  startedAt: string;
  finishedAt: string;
}

const calculateConversationDuration = ({ startedAt, finishedAt }: DurationProps): string => {
  const createdDate = new Date(startedAt);
  const updatedDate = new Date(finishedAt);

  const durationMs = updatedDate.getTime() - createdDate.getTime();

  const millisecondsInSecond = 1000;
  const millisecondsInMinute = millisecondsInSecond * 60;
  const millisecondsInHour = millisecondsInMinute * 60;

  const totalHours = Math.floor(durationMs / millisecondsInHour);
  const hours = totalHours % 24;
  const minutes = Math.floor((durationMs % millisecondsInHour) / millisecondsInMinute);
  const seconds = Math.floor((durationMs % millisecondsInMinute) / millisecondsInSecond);

  const formattedHours = `${hours.toString().padStart(2, '0')}`;
  const formattedMinutes = `${minutes.toString().padStart(2, '0')}`;
  const formattedSeconds = `${seconds.toString().padStart(2, '0')}`;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export default calculateConversationDuration;
