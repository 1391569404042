import React from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconChevronDown, IconPlus } from '@tabler/icons-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu';
import Routes from '@/router/routes';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import axiosInstance from '@/axios/axiosInstance';
import { useMutateRenameOrganization, useMutationsGoToOtherOrganization } from '@/reactQuery/post';
import FullPageLoader from '../atoms/Loader/FullPageLoader';
import { useGetUserData } from '@/reactQuery/get';
import { toast } from '../ui/use-toast';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';
import CogIcon from '../icons/CogIcon';
import { cn } from '@/lib/utils';
import BrushIcon from '../icons/BrushIcon';
import { Button } from '../ui/button';
import useTranslation from '@/hooks/useTranslation';

interface IOrganizationProps {
  name: string,
  members: IMembers[],
  owner_id?: boolean;
  _id: string;
  mainWorkspace: string;
}

interface IMembers {
  invite_accepted: boolean;
  role: string;
  user_id: string;
}

interface IOrg {
  currentOrganization: string | undefined
  orgData: IOrganizationDataProps;
}

function OrganizationDropDown({ currentOrganization, orgData }: IOrg) {
  const { lang } = useTranslation();
  const { mutateAsync: mutateMoveToAnotherOrganization, isPending } = useMutationsGoToOtherOrganization();
  const { data: user } = useGetUserData();
  const { isUserOwner, isUserAdmin } = useOrganizationRoles();
  const { currentTheme } = useTheme();
  const { pathname } = useLocation();
  const { organization_id } = useParams();

  const handleChangeOrganization = async (data: { org_id: string, workspace_id: string }) => {
    await mutateMoveToAnotherOrganization(data);
  };

  const { mutateAsync: mutateAsyncAddPersonalOrg } = useMutateRenameOrganization();

  const { data: orgDataAll } = useQuery({
    queryFn: () => axiosInstance.get('/organizations/get_all'),
    queryKey: ['all_organizations_data', { currentOrganization }],
  });

  const organizationsToShow = orgDataAll?.data?.filter((item: { isHidden: boolean }) => item?.isHidden !== true)
    ?.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));

  const personalOrganizationHidden = orgDataAll?.data?.find((item: { isHidden: boolean }) => item?.isHidden === true);

  const handleAddingPersonalOrganization = async () => {
    try {
      await mutateAsyncAddPersonalOrg({ formData: { isHidden: false }, organization_id: personalOrganizationHidden?._id });
      toast({ description: `${lang.get('msg.personalOrganizationSuccessfullyActivated')}` });
    } catch {
      toast({ description: `${lang.get('msg.errorPleaseTryAgain')}`, variant: 'destructive' });
    }
  };

  if (isPending) return <FullPageLoader />;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="flex items-center justify-center">
        <div className="flex items-center gap-2 border-none outline-none cursor-pointer select-none ring-offset-transparent ring-transparent focus-visible:ring-0 focus-visible:shadow-none focus-visible:ring-opacity-0">
          <div className="flex items-center justify-center uppercase border rounded-md min-w-[40px] min-h-[40px]">
            <Avatar
              className="object-cover w-full h-full rounded-md"
            >
              {/* add img later */}
              <AvatarImage src="" className="object-cover max-w-[40px] max-h-[40px] rounded-md" />
              <AvatarFallback
                style={{ backgroundColor: currentTheme?.['chat-primary'] }}
                className="h-[40px] w-[40px] text-2xl font-semibold py-0.5 px-1 text-white rounded-md"
              >
                {orgData?.name?.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </div>
          <h2 className="font-semibold">{orgData?.name}</h2>
          <IconChevronDown size={17} className="mt-0.5" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="relative w-72 ml-5 overflow-y-auto z-60 max-h-[500px]">
        <DropdownMenuLabel className="text-[13px] pb-0.5">{lang.get('msg.organization')}</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuItem
            disabled={!isUserOwner && !isUserAdmin}
            className={cn(
              'cursor-pointer',
              pathname === `${Routes.organization.path}/${organization_id}${Routes.organizational_settings.path}` && '!bg-neutral-100',
            )}
          >
            <NavLink
              className="flex items-center w-full gap-2"
              to={`${Routes.organization.path}/${organization_id}${Routes.organizational_settings.path}`}
              aria-disabled={!isUserOwner || !isUserAdmin}
            >
              <div
                style={{ backgroundColor: currentTheme?.primary }}
                className="h-[35px] flex items-center justify-center w-[35px] py-0.5 px-1 text-white rounded-md"
              >
                <CogIcon className="size-6" />
              </div>
              {lang.get('msg.organizationalSettings')}
            </NavLink>
          </DropdownMenuItem>
          <DropdownMenuItem
            className={cn(
              'cursor-pointer',
              pathname === `${Routes.organization.path}/${organization_id}${Routes.projects_rebranding.path}` && '!bg-neutral-100',
            )}
          >
            <NavLink
              className="flex items-center w-full gap-2"
              to={`${Routes.organization.path}/${organization_id}${Routes.projects_rebranding.path}`}
            >
              <div
                style={{ backgroundColor: currentTheme?.primary }}
                className="h-[35px] flex items-center justify-center w-[35px] py-0.5 px-1 text-white rounded-md"
              >
                <BrushIcon className="size-6" />
              </div>
              {lang.get('msg.projectBranding')}
            </NavLink>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuLabel className="text-[13px] pb-0">
          {lang.get('msg.yourOrganizations')}
          {' '}
          (
          {organizationsToShow?.length}
          )
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          {React.Children.toArray(organizationsToShow?.map((item: IOrganizationProps) => (
            <DropdownMenuItem className={`pr-0 cursor-pointer ${currentOrganization === item._id && '!bg-neutral-100'}`}>
              <Link
                to={`${Routes.organization.path}/${item?._id}${Routes.workspace.path}/${item?.mainWorkspace}`}
                onClick={(e) => {
                  if (!item?.mainWorkspace) {
                    e.preventDefault();
                    toast({
                      description:
                        'The owner or admin is in the process of adding you to the specific workspace within the organization. Thanks for your patience',
                    });
                  } else if (currentOrganization !== item._id) {
                    handleChangeOrganization({
                      org_id: item?._id,
                      workspace_id: item?.mainWorkspace,
                    });
                  }
                }}
                className="flex items-center w-full gap-2 px-0"
              >
                <div className="flex items-center justify-center uppercase border rounded-md min-w-[35px] min-h-[35px]">
                  <Avatar className="object-cover w-full h-full rounded-md">
                    {/* add img later */}
                    <AvatarImage src="" className="object-cover max-w-[35px] max-h-[35px] rounded-md" />
                    <AvatarFallback
                      style={{ backgroundColor: currentTheme?.['chat-primary'] }}
                      className="h-[35px] w-[35px] font-semibold py-0.5 px-1 text-white text-lg rounded-md"
                    >
                      {item?.name?.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <p className="text-[13px] font-semibold leading-4 max-w-[120px] truncate">
                      {item?.name}
                    </p>
                    {user?._id === item?.owner_id
                      && <span className="text-[11px] bg-gray-200 px-2 leading-none py-0.5 rounded-full">{lang.get('msg.owner')}</span>}
                  </div>
                  <p className="text-[11px] text-start text-light-gray leading-4 max-w-[165px] truncate">
                    {item.members.filter((member) => member.invite_accepted).length}
                    {' '}
                    {item.members.filter((member) => member.invite_accepted).length > 1
                      ? lang.get('msg.members')
                      : lang.get('msg.member')}
                  </p>
                </div>
              </Link>
            </DropdownMenuItem>
          )))}
          {!!personalOrganizationHidden && (
            <div className="w-full px-2">
              <Button onClick={handleAddingPersonalOrganization} variant="default" className="w-full mt-1 mb-1">
                <IconPlus size={18} className="mr-2 min-w-[18px]" />
                <span className="truncate">{lang.get('msg.addPersonalOrganization')}</span>
              </Button>
            </div>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default OrganizationDropDown;
