import ProjectBrandingTable from '@/components/projectBrandingTable/ProjectBrandingTable';
import PageTitle from '@/components/titles/PageTitle';

function ProjectsBranding() {
  return (
    <main className="flex flex-col w-full h-full">
      <PageTitle title="projectBranding" />
      <div className="flex flex-col w-full h-full">
        <ProjectBrandingTable />
      </div>
    </main>
  );
}

export default ProjectsBranding;
