import { IconQrcode } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { QRCodeCanvas } from 'qrcode.react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { ECompanyThemeName } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import toCamelCase from '@/utils/toCamelCase';
import DownloadQRCode from './DownloadQrCode';

interface IQrCodeProps {
  providerName: string;
  projectLink: string;
}

function QrCodePanelComponent({ providerName, projectLink }:IQrCodeProps) {
  const { lang } = useTranslation();
  const { themeName, currentTheme } = useTheme();

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="default"
          type="button"
        >
          <IconQrcode size={20} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="mb-1">{lang.get('msg.QRCode')}</DialogTitle>
          <div className="text-dark-text">
            <p className="text-sm">
              {themeName === ECompanyThemeName.EMPATHY ? lang.get('msg.scanQRCodeToGetTellet_empathy')
                : lang.get('msg.scanQRCodeToGetTellet')}
            </p>
            <div className="flex items-center justify-center w-full my-4">
              <QRCodeCanvas
                id={`qrcode-${projectLink}?source=${toCamelCase(providerName)}`}
                value={`${projectLink}?source=${toCamelCase(providerName)}`}
                size={280}
                bgColor="#ffffff"
                fgColor={currentTheme?.primary}
                level="M"
                includeMargin
              />
            </div>
          </div>
          <Button
            variant="default"
            onClick={() => DownloadQRCode(`qrcode-${projectLink}?source=${toCamelCase(providerName)}`)}
          >
            {lang.get('msg.downloadQRCode')}
          </Button>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default QrCodePanelComponent;
