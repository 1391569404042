import { useState } from 'react';
import { IconCode } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from '@/components/ui/dialog';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import toCamelCase from '@/utils/toCamelCase';

interface EmbedLinkProps {
  providerName: string;
  projectLink: string;
  index: number;
}

function EmbedLinkPanelComponent({ providerName, projectLink, index }: EmbedLinkProps) {
  const { lang } = useTranslation();
  const [embedDialogOpen, setEmbedDialogOpen] = useState<number | null>(null);

  const copyEmbedCode = (link: string) => {
    try {
      navigator.clipboard.writeText(`<iframe src="${link}" allow="camera; microphone; autoplay;" title="Tellet" width="400px" height="800px" frameBorder="0"></iframe>`);
      toast({ description: `✅ ${lang.get('msg.codeCopied')}!` });
      setEmbedDialogOpen(null);
    } catch (err) {
      toast({ title: `${lang.get('msg.codeNotCopied')}`, variant: 'destructive' });
    }
  };

  return (
    <Dialog
      open={embedDialogOpen === index}
      onOpenChange={(isOpen) => setEmbedDialogOpen(isOpen ? index : null)}
    >
      <DialogTrigger asChild>
        <Button variant="default" type="button">
          <IconCode size={20} />
        </Button>
      </DialogTrigger>

      <DialogContent className="max-w-xl bg-gray-100">
        <DialogHeader>
          <div className="py-4 text-dark-text center">
            <code className="px-4 py-10 bg-white rounded-xl">
              {`<iframe src="${projectLink}?source=${toCamelCase(providerName)}" allow="camera; microphone; autoplay;" title="Tellet" width="400px" height="800px" frameBorder="0"></iframe>`}
            </code>
          </div>
          <Button
            variant="default"
            type="button"
            onClick={() => copyEmbedCode(`${projectLink}?source=${toCamelCase(providerName)}`)}
          >
            {lang.get('msg.copyCode')}
          </Button>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export default EmbedLinkPanelComponent;
