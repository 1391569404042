import { IconAlertCircle } from '@tabler/icons-react';

function ErrorMessage({ data }: TErrorText) {
  return (
    <div className="flex gap-3 md700:gap-1.5 max-w-[75%] lg1000:max-w-full self-start">
      <div className="flex bg-white rounded-lg py-2.5 px-3 shadow">
        <div className="flex items-center">
          <IconAlertCircle size={25} className="text-red-400" />
        </div>
        <p className="flex rounded-lg px-3 md700:ml-0 text-dark bg-white text-dark">
          {data}
        </p>
      </div>
    </div>
  );
}

export default ErrorMessage;
