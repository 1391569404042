// import Image from '@/components/atoms/Image';
import { useSearchParams } from 'react-router-dom';
import { AlertDialogAction, AlertDialogCancel } from '@/components/ui/alert-dialog';
import { Input } from '@/components/ui/input';
import { ECreateThemeModalType } from '@/enums';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';
import useTranslation from '@/hooks/useTranslation';

interface IBrandingInputsProps {
  primary1Color: string;
  primary2Color: string;
  secondaryColor: string;
  backgroundColor: string;
  progressBarColor: string;
  consentBackgroundColor: string;
  setPrimary1Color: React.Dispatch<React.SetStateAction<string>>;
  setPrimary2Color: React.Dispatch<React.SetStateAction<string>>;
  setSecondaryColor: React.Dispatch<React.SetStateAction<string>>;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  setProgressBarColor: React.Dispatch<React.SetStateAction<string>>;
  setConsentBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  setOpenModal: (open: boolean) => void;
  handleExitModal: () => void;
  brandingName: string;
  setBrandingName: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: () => void;
  themeIsDefault: boolean;
}

function ProjectBrandingInputs({ primary1Color, primary2Color, secondaryColor,
  backgroundColor, progressBarColor, consentBackgroundColor, setPrimary1Color,
  setPrimary2Color, setSecondaryColor, setBackgroundColor, setProgressBarColor,
  setConsentBackgroundColor, setOpenModal, handleExitModal,
  brandingName, setBrandingName, onSubmit, themeIsDefault }: IBrandingInputsProps) {
  const [searchParams] = useSearchParams();
  const modal_mode = searchParams.get('mode');
  const { isUserAdmin, isUserOwner } = useOrganizationRoles();
  const { lang } = useTranslation();

  const isNotAdminOrOwner = !isUserAdmin && !isUserOwner;

  // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0];
  //   if (file) {
  //     const fileURL = URL.createObjectURL(file);
  //     setHandleLogo(fileURL);
  //   }
  // };

  return (
    <div className="flex flex-col justify-between w-full h-full gap-2">
      <div className="flex flex-col h-full gap-5 pb-2 overflow-y-auto text-sm">
        <label className="flex flex-col w-full gap-1 font-medium">
          {lang.get('msg.name')}
          :
          <Input
            type="text"
            value={brandingName}
            onChange={(e) => setBrandingName(e.target.value)}
            placeholder="Enter name"
          />
        </label>
        <div className="flex items-center w-full gap-3">
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.progressBarAndMicrophone')}
            :
            <Input type="color" value={primary1Color} onChange={(e) => setPrimary1Color(e?.target?.value)} />
          </label>
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.participantMessage')}
            :
            <Input type="color" value={primary2Color} onChange={(e) => setPrimary2Color(e?.target?.value)} />
          </label>
        </div>
        <div className="flex items-center w-full gap-3">
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.interviewerMessage')}
            :
            <Input type="color" value={secondaryColor} onChange={(e) => setSecondaryColor(e?.target?.value)} />
          </label>
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.background')}
            :
            <Input type="color" value={backgroundColor} onChange={(e) => setBackgroundColor(e?.target?.value)} />
          </label>
        </div>
        <div className="flex items-center w-full gap-3">
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.progressBarBackground')}
            :
            <Input type="color" value={progressBarColor} onChange={(e) => setProgressBarColor(e?.target?.value)} />
          </label>
          <label className="flex flex-col w-full gap-1 font-medium">
            {lang.get('msg.consentScreenBackground')}
            :
            <Input type="color" value={consentBackgroundColor} onChange={(e) => setConsentBackgroundColor(e?.target?.value)} />
          </label>
        </div>
        {/* <div className="flex w-full">
          <label className="flex flex-col w-full gap-1 font-medium">
            Company logo:
            <Input type="file" onChange={handleFileChange} />
          </label>
          {handleLogo && <Image src={handleLogo} alt="Company Logo" className="object-contain w-full mt-1 max-h-14" />}
        </div> */}
      </div>
      <div className="flex items-center w-full gap-4">
        <AlertDialogCancel className="w-full" onClick={() => { setOpenModal(false); handleExitModal(); }}>{lang.get('msg.cancel')}</AlertDialogCancel>
        {modal_mode === ECreateThemeModalType.CREATE && !themeIsDefault && !isNotAdminOrOwner
          && <AlertDialogAction className="w-full" onClick={onSubmit}>{lang.get('msg.create')}</AlertDialogAction>}
        {modal_mode === ECreateThemeModalType.EDIT && !themeIsDefault && !isNotAdminOrOwner
          && <AlertDialogAction className="w-full" onClick={onSubmit}>{lang.get('msg.save')}</AlertDialogAction>}
      </div>
    </div>
  );
}

export default ProjectBrandingInputs;
