import { cn } from '../../lib/utils';

interface IIconProps {
  className: string
}

function ChevronDoubleDownIcon({ className }: IIconProps) {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={cn(className)}>
  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
  </svg>
  );
}

export default ChevronDoubleDownIcon;
