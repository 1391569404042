import { toast } from '@/components/ui/use-toast';

const DownloadQRCode = (el: string) => {
  const canvas = document.getElementById(`${el}`) as HTMLCanvasElement | null;

  if (!canvas) {
    toast({
      title: 'Error, please try again.',
      variant: 'destructive',
    });
    return;
  }

  const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  const downloadLink = document.createElement('a');

  downloadLink.href = pngUrl;
  downloadLink.download = 'telletQRCode.png';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default DownloadQRCode;
