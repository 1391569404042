import { ReactNode } from 'react';
import useTranslation from '@/hooks/useTranslation';
import ToolTip from '../tooltip/ToolTip';

interface IProps {
  children: ReactNode;
  title: string;
  tooltip?: string
  notRequired?: boolean;
  icon?: ReactNode;
}

function RequiredLabel({ children, title, tooltip, icon, notRequired = false }: IProps) {
  const { lang } = useTranslation();

  return (
    <label className="relative flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <p className="flex items-center gap-1 text-[15px] font-medium">
          {icon}
          {lang.get(`msg.${title}`)}
          {!notRequired && <span className="text-red-600">*</span>}
        </p>
        {tooltip && <ToolTip text={tooltip} className="max-w-lg border-[3px] border-primary text-sm z-100" />}
      </div>
      {children}
    </label>
  );
}

export default RequiredLabel;
