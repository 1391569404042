import AudioPlayer from './AudioPlayer';
import { cn } from '../../../lib/utils';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios'
import { useParams } from 'react-router-dom'

type TMessageAudio = {
  isUser: boolean;
} & TMessageAtachment;

function AudioMessage({ url, isUser }: TMessageAudio) {
  const { organization_id, project_id } = useParams()
  const projectId = !!organization_id ? `/${project_id}` : window.location.pathname;

  const { data: themeData } = useQuery({
    queryFn: () => axios.get(`${import.meta.env.VITE_API_BASE_URL}/projects/get${projectId}/theme`),
    queryKey: ['chat_themes_branding', { projectId }],
    enabled: !!projectId,
    select: (data) => data?.data,
  });

  return (
    <div>
      <div
        style={{ backgroundColor: isUser ? themeData?.['chat-primary'] : "#FFFFFF" }}
        className={cn(isUser ? 'text-white' : 'text-dark-text')}>
        <AudioPlayer url={url} isUser={isUser} />
      </div>
    </div>
  );
}

export default AudioMessage;
