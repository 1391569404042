import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import axiosInstance from '@/axios/axiosInstance';
import { useGetUserData } from '@/reactQuery/get';
import { EOrganizationRoles } from '@/enums';
import useErrorStore from '@/store/ErrorStore';

const useOrganizationRoles = () => {
  const { data: user, isLoading: isUserLoading } = useGetUserData();
  const { organization_id } = useParams();
  const { setShowError } = useErrorStore();

  const { data: orgData, isLoading: isOrganizationLoading, error } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get/${organization_id}`),
    queryKey: ['current_organization_data', { organization_id }],
  });

  const organizationDataError = error as AxiosError;

  const userIDsMap = orgData?.data?.members?.map((item: { user_id: string }) => item.user_id);

  const getOrganizationMembers = orgData?.data?.members?.map(
    (item: { user_id: string, role: string }) => item,
  );

  const getUsersWithRole = (role: string) => {
    const singleRoleGroup = getOrganizationMembers?.filter(
      (item: { role: string }) => item?.role === role,
    );

    return singleRoleGroup;
  };

  const workspaceMembersWithRole = (role: string) => {
    const roleMap = getUsersWithRole(role)?.map(
      (item: { user_id: string }) => item?.user_id,
    );

    return roleMap;
  };

  const isUserOwner = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EOrganizationRoles.OWNER)?.includes(userId)
       && userId === user?._id,
  );

  const isUserAdmin = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EOrganizationRoles.ADMIN)?.includes(userId)
       && userId === user?._id,
  );

  const isUserEditor = userIDsMap?.some(
    (userId: string) => workspaceMembersWithRole(EOrganizationRoles.EDITOR)?.includes(userId)
       && userId === user?._id,
  );

  const isLoading = isUserLoading || isOrganizationLoading;

  if (organizationDataError?.status === 404) {
    setShowError(true);
  }

  return {
    isUserOwner,
    isUserEditor,
    isUserAdmin,
    isLoading,
    organizationDataError,
  };
};

export default useOrganizationRoles;
