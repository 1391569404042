import { IconArrowRight } from '@tabler/icons-react';
import Image from '@/components/atoms/Image';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import getTextColor from '@/utils/getTextColorContrast';

interface IConsentPreview {
  consentBackgroundColor: string;
  handleLogo: string | null;
  primary1Color: string;
}

function ProjectBrandingConsentPreview({ consentBackgroundColor, handleLogo, primary1Color }: IConsentPreview) {
  return (
    <div
      style={{ backgroundColor: consentBackgroundColor, color: getTextColor(consentBackgroundColor) }}
      className="relative flex flex-col items-center w-full h-full py-8 text-left rounded-lg shadow"
    >
      {handleLogo && <Image height={50} width={50} src={handleLogo} alt="logo" className="absolute bottom-2.5 right-3" />}
      <div className="w-full max-w-[300px] h-full justify-between flex flex-col">
        <div className="flex flex-col w-full">
          <p className="mb-6 text-4xl font-bold">Hi!</p>
          <p className="mb-4 text-sm">
            Are you ready to share your opinion with us?
          </p>
          <p className="text-sm">
            Before we get started, please check out our
            {' '}
            <u className="font-bold">Interview Terms</u>
            {' '}
            and
            {' '}
            <u className="font-bold">Privacy Policy</u>
            {' '}
            to see how we&apos;ll use your feedback.
          </p>
        </div>
        <div className="flex flex-col self-end gap-7">
          <div className="flex items-center gap-3">
            <p className="text-sm max-w-40">
              I have read and accept the interview terms
            </p>
            <Checkbox
              style={{ borderColor: getTextColor(consentBackgroundColor) }}
              checked
              className="w-9 h-9 border-2 mt-2 border-white rounded-xl data-[state=checked]:bg-white data-[state=checked]:text-chat-primary"
            />
          </div>
          <Button
            style={{ backgroundColor: primary1Color, color: getTextColor(primary1Color) }}
            className="flex items-center self-end w-full border-2 border-white max-w-28 rounded-xl"
          >
            Let&apos;s go
            <IconArrowRight size={18} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProjectBrandingConsentPreview;
