import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import useTranslation from '@/hooks/useTranslation';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useGetUserData } from '@/reactQuery/get';
import axiosInstance from '@/axios/axiosInstance';
import { toast } from '@/components/ui/use-toast';
import { useMutationsUpdateUser } from '@/reactQuery/post';
import LanguageDropDown from '@/components/Navbar/LanguageDropDown';
import { EStatusCode } from '@/enums';

const defaultValues = {
  name: '',
};

const passwordChangeDefaultValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const validationSchemaProfile = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const validationSchemaPassword = Yup.object().shape({
  oldPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('This field is required'),
});

type TMyProfile = typeof defaultValues;
type TPasswordChange = typeof passwordChangeDefaultValues;

function MyProfile() {
  const { data: user } = useGetUserData();
  const { lang } = useTranslation();
  const [changePasswordError, setChangePasswordError] = useState<string>('');
  const [updateProfileError, setUpdateProfileError] = useState<string>('');
  const { mutateAsync } = useMutationsUpdateUser();
  const { currentTheme } = useTheme();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues, resolver: yupResolver(validationSchemaProfile) });

  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    reset: passwordReset,
    formState: { errors: passwordErrors, isSubmitting: isPasswordSubmitting },
  } = useForm({
    defaultValues: passwordChangeDefaultValues,
    resolver: yupResolver(validationSchemaPassword),
  });

  useEffect(() => {
    if (user) {
      setValue('name', user.name);
    }
  }, [setValue, user, user?.name]);

  const onPasswordSubmit = async (data: TPasswordChange) => {
    try {
      setChangePasswordError('');
      await axiosInstance.post('/users/change_password', {
        old_password: data.oldPassword,
        new_password: data.newPassword,
      });
      passwordReset();
      toast({
        title: `✅ ${lang.get('msg.passwordUpdated')}`,
        description: lang.get('msg.passwordUpdatedDescription'),
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.OLD_PASSWORD_NOT_MATCH) {
        setChangePasswordError(lang.get('msg.oldPasswordIncorrect'));
      }
    }
  };

  const onProfileSubmit = async (_data: TMyProfile) => {
    try {
      setUpdateProfileError('');
      await mutateAsync(_data);
      toast({
        title: `✅ ${lang.get('msg.profileUpdated')}`,
        description: lang.get('msg.profileUpdatedDescription'),
      });
    } catch (error) {
      setUpdateProfileError(lang.get('msg.updateProfileError'));
    }
  };

  return (
    <div
      style={{ color: currentTheme?.['primary-text'] }}
      className="max-w-3xl mx-3 min450:mx-0"
    >
      <h2
        className="text-lg font-semibold"
      >
        {lang.get('msg.profile')}
      </h2>
      <p
        style={{ color: currentTheme?.['secondary-text'] }}
        className="mt-1 mb-6 text-sm text-light-gray"
      >
        {lang.get('msg.profileSubtitle')}
      </p>
      <hr />
      <form className="h-full max-w-lg mt-5" onSubmit={handleSubmit(onProfileSubmit)}>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative"
        >
          {lang.get('msg.name')}
          <Input {...register('name')} hasError={!!errors.name} type="text" />
          {errors.name?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {errors.name?.message}
            </p>
          )}
          <p
            style={{ color: currentTheme?.['secondary-text'] }}
            className="text-xs"
          >
            {lang.get('msg.nameInfoMessage')}
          </p>
        </label>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex mt-4  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative"
        >
          {lang.get('msg.email')}
          <div className="peer rounded-md border border-natural-700 transition-all flex h-10 w-full shadow-sm focus-within:text-gray-900 px-3.5 py-2.5 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:shadow-fc-secondary pointer-events-none bg-gray-100 text-gray-400 invalid:shadow-hv-error invalid:bg-white invalid:text-gray-900">
            {user?.email || '--'}
          </div>
          <p
            style={{ color: currentTheme?.['secondary-text'] }}
            className="text-xs"
          >
            {lang.get('msg.ifYouWouldLikeToChangeTheEmailAddressAssociatedWithYourProfilePleaseReachOut')}
            {' '}
            <a className="font-bold underline" href="mailto:info@tellet.ai">info@tellet.ai</a>
            .
          </p>
          {updateProfileError && (
            <p className="absolute right-0 text-sm text-red-600 -bottom-4">
              {lang.get('msg.unableToUpdateProfile')}
            </p>
          )}
        </label>
        <Button
          variant="default"
          disabled={isSubmitting}
          className="mt-6"
        >
          {lang.get('msg.updateProfile')}
        </Button>
      </form>

      <div className="flex flex-col">
        <h2 className="mt-10 text-lg font-semibold">{lang.get('msg.changeAccountLanguage')}</h2>
        <p
          style={{ color: currentTheme?.['secondary-text'] }}
          className="mt-1 mb-6 text-sm"
        >
          {lang.get('msg.youCanChangeAccountLanguageHere')}
        </p>
        <hr />
        {/* language dropdown */}
        <div className="flex items-center gap-4 mt-4">
          <p>
            {lang.get('msg.currentLanguage')}
            :
          </p>
          <LanguageDropDown mutateAsync={mutateAsync} />
        </div>
      </div>

      <form className="max-w-lg" onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
        <h2 className="mt-10 text-lg font-semibold">{lang.get('msg.changePassword')}</h2>
        <p
          style={{ color: currentTheme?.['secondary-text'] }}
          className="mt-1 mb-6 text-sm"
        >
          {lang.get('msg.changePasswordSubtitle')}
        </p>
        <hr />

        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.oldPassword')}
          <Input
            {...passwordRegister('oldPassword')}
            hasError={!!passwordErrors.oldPassword}
            type="password"
          />
          <p
            style={{ color: currentTheme?.['secondary-text'] }}
            className="text-xs"
          >
            {lang.get('msg.oldPasswordSubtitle')}
          </p>
          {passwordErrors.oldPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {passwordErrors.oldPassword?.message}
            </p>
          )}
        </label>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex  flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.newPassword')}
          <Input
            {...passwordRegister('newPassword')}
            hasError={!!passwordErrors.newPassword}
            type="password"
          />
          <p
            style={{ color: currentTheme?.['secondary-text'] }}
            className="text-xs"
          >
            {lang.get('msg.newPasswordSubtitle')}
          </p>
          {passwordErrors.newPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {passwordErrors.newPassword?.message}
            </p>
          )}
        </label>
        <label
          style={{ color: currentTheme?.['primary-text'] }}
          className="flex flex-col w-full gap-2 items-start text-sm font-semibold leading-[1.5] relative mt-6"
        >
          {lang.get('msg.confirmPassword')}
          <Input
            {...passwordRegister('confirmNewPassword')}
            hasError={!!passwordErrors.confirmNewPassword}
            type="password"
          />
          <p
            style={{ color: currentTheme?.['secondary-text'] }}
            className="text-xs"
          >
            {lang.get('msg.confirmPasswordSubtitle')}
          </p>
          {passwordErrors.confirmNewPassword?.message && (
            <p className="absolute top-0 ml-1 text-sm text-red-600 right-2">
              {passwordErrors.confirmNewPassword?.message}
            </p>
          )}
          {changePasswordError && (
            <p className="absolute right-0 ml-1 text-sm text-red-600 -bottom-4">
              {changePasswordError}
            </p>
          )}
        </label>
        <Button
          variant="default"
          disabled={isPasswordSubmitting}
          className="mt-6"
        >
          {lang.get('msg.updatePassword')}
        </Button>
      </form>
    </div>
  );
}

export default MyProfile;
