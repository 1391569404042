import { Dispatch, MouseEvent, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { FieldErrors, UseFormTrigger } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { IconChevronDown, IconUserOff } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { LangContext } from '@/context/LangProvider';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import axiosInstance from '@/axios/axiosInstance';

interface IOptionProps {
  name: string;
  email: string;
  _id: string;
  invite_accepted: boolean;
}

interface IFormValues {
  email: string;
  name: string;
}

interface IModalProps {
  errors: FieldErrors<IFormValues>;
  field: {
    onChange: (value: string) => void;
    name: string;
    value: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trigger: UseFormTrigger<any>
  selectedUser: string;
  setSelectedUser: Dispatch<SetStateAction<string>>;
  workspaceUsersData: {
    _id: string;
  }[];
}

function InviteToWorkspaceDropdown({ errors, field, trigger, selectedUser, setSelectedUser, workspaceUsersData }: IModalProps) {
  const { lang } = useContext(LangContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const { organization_id } = useParams();
  const [showWorkspaceUsers, setShowWorkspaceUsers] = useState(false);

  const { data: orgMembersData } = useQuery({
    queryFn: () => axiosInstance.get(`/organizations/get_members/${organization_id}`),
    queryKey: ['organization_members'],
  });

  const availableUsers = orgMembersData?.data?.filter(
    (orgUser: IOptionProps) => !workspaceUsersData.some((workspaceUser) => workspaceUser._id === orgUser._id),
  );

  const handleResultClick = (value: string, event: MouseEvent) => {
    event.stopPropagation();
    setSelectedUser(value);
    field.onChange(value);
    trigger(field.name);
    setTimeout(() => setShowWorkspaceUsers(false), 0);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    if (showWorkspaceUsers) {
      const handleClickOutside = (event: globalThis.MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setShowWorkspaceUsers(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    return undefined;
  }, [showWorkspaceUsers]);

  return (
    <div className="relative w-full" ref={containerRef}>
      <div className="flex flex-col w-full">
        <button
          type="button"
          onClick={() => setShowWorkspaceUsers(!showWorkspaceUsers)}
          className={`w-80 whitespace-nowrap font-medium outline-none bg-gray-50 text-dark-text rounded-tr-none text-sm justify-between flex items-center px-3 rounded-l-md rounded-br-none h-10 border !border-r-transparent shadow ${errors?.email?.message && 'border-red-600/50'
          }`}
        >
          <p>{selectedUser || lang.get('msg.pickAUserToJoinTheWorkspace')}</p>
          <IconChevronDown size={15} />
        </button>
        <p
          className={`text-xs font-medium text-red-600 opacity-0 absolute -bottom-5 ${errors?.email?.message && 'opacity-100'
          }`}
        >
          {errors?.email?.message}
        </p>
      </div>
      {showWorkspaceUsers && (
        <div className="absolute z-50 flex flex-col w-[150%] md700:w-full overflow-y-auto bg-white border shadow rounded-b-md top-[39px] max-h-64">
          {/* clear selection */}
          {availableUsers?.length !== 0 && (
            <button
              type="button"
              onClick={(event) => handleResultClick('', event)}
              className="flex px-4 py-3 text-sm font-normal bg-gray-100 border-none outline-none cursor-pointer hover:bg-secondary-background"
            >
              <div className="flex items-center gap-3">
                Clear selection
              </div>
            </button>
          )}

          {availableUsers?.map((item: IOptionProps) => (
            <button
              type="button"
              onClick={(event) => handleResultClick(item?.email, event)}
              key={item?._id}
              className="relative flex px-4 py-3 text-sm font-normal bg-white border-none outline-none cursor-pointer disabled:bg-gray-50 hover:bg-secondary-background"
            >
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-3 lowercase">
                  <div className="flex items-center justify-center  uppercase border rounded-full min-w-[38px] min-h-[38px]">
                    <Avatar>
                      <AvatarImage src="" />
                      <AvatarFallback className="font-semibold text-dark-text">
                        {item?.name ? getInitials(item?.name) : <IconUserOff size={20} />}
                      </AvatarFallback>
                    </Avatar>
                  </div>
                </div>
                <div className="flex flex-col text-left gap-0.5">
                  <p className="text-[15px] font-semibold leading-4">{item.name ?? `${lang.get('msg.accountCreationPending')}...`}</p>
                  <p className="text-sm font-normal leading-5 text-light-gray">{item.email}</p>
                </div>
              </div>
            </button>
          ))}

          {availableUsers?.length === 0
            && <p className="mx-auto text-sm font-medium py-2.5">{lang.get('msg.allUsersInYourOrganizationAreAlreadyPartOfThisWorkspace')}</p>}
        </div>
      )}
    </div>
  );
}

export default InviteToWorkspaceDropdown;
