import { create } from 'zustand';
import themes, { Themes, ThemeName } from '../src/theme/themes';

type State = {
  theme: ThemeName;
  allThemes: Themes;
};

type Actions = {
  setTheme: (value: ThemeName) => void;
  changeTheme: (id: ThemeName) => void;
};

const useThemeStore = create<State & Actions>((set) => {
  const defaultTheme: ThemeName = 'default';

  return {
    theme: defaultTheme,
    allThemes: themes,
    setTheme: (value) => set({ theme: value }),
    changeTheme: (companyTheme) => {
      const newTheme = themes[companyTheme] ? companyTheme : defaultTheme;
      set({ theme: newTheme });
    },
  };
});

export default useThemeStore;
