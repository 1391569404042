import { IconCheck, IconChecks } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';

interface IMessageStatus {
  seen: boolean | undefined;
  received: boolean | undefined;
}

function MessageStatus({ seen = false, received = false }: IMessageStatus) {
  const {currentTheme} = useTheme();
  const isReceived = received && !seen;
  if (isReceived) return <IconChecks className="self-end ml-1 text-gray-400" size={16} />;
  return seen ? (
    <IconChecks 
    style={{color: currentTheme?.['chat-primary']}}
    className="self-end ml-1" size={16} />
  ) : (
    <IconCheck className="self-end ml-1 text-gray-400" size={16} />
  );
}

export default MessageStatus;
