import './dots-animation.scss'
const BouncingDots = () => {
  return (<div
    className="z-30 flex items-center justify-center gap-2 font-medium text-center"
  >
    {/* dots animation */}
    <div className="-mb-2.5 bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>)
};

export default BouncingDots;
