import { IconKeyboard, IconMicrophone, IconMoodSmileBeam, IconPhoto, IconPlayerPlayFilled, IconPlayerStop, IconVolume, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { Input } from '@/components/ui/input';
import getTextColor from '@/utils/getTextColorContrast';

interface IChatPreviewProps {
  progressBarColor: string;
  primary1Color: string;
  secondaryColor: string;
  primary2Color: string;
}

function ProjectBrandingChatPreview({ progressBarColor, primary1Color, secondaryColor,
  primary2Color }: IChatPreviewProps) {
  const [openVoiceBox, setOpenVoiceBox] = useState(false);
  const [showVoiceButton, setShowVoiceButton] = useState(true);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="relative flex flex-col w-full h-full pt-5 overflow-hidden bg-white border rounded-lg shadow">
        {/* progress */}
        <div
          style={{ backgroundColor: progressBarColor }}
          className="absolute top-0 left-0 right-0 w-full h-6 rounded-t-lg"
        >
          <div
            style={{ backgroundColor: primary1Color }}
            className="w-1/3 h-6 rounded-r-full rounded-tl-lg"
          >
          </div>
        </div>
        {/* text */}
        <div className="flex flex-col w-full h-full gap-3 p-4 overflow-y-auto text-sm font-medium">
          {/* AI */}
          <p
            style={{ backgroundColor: secondaryColor, color: getTextColor(secondaryColor) }}
            className="max-w-[70%] p-3 text-dark-text rounded-lg rounded-bl-none self-start"
          >
            Hello! How are you doing today? I hope you&apos;re feeling well and ready to take part in this interview. Are you prepared to get started? 😊
          </p>
          {/* User */}
          <p
            style={{ backgroundColor: primary2Color, color: getTextColor(primary2Color) }}
            className="max-w-[70%] p-3 text-white rounded-lg rounded-br-none self-end"
          >
            Thank you for asking! I&apos;m doing well, and I&apos;m ready to get started with the interview. Looking forward to our conversation!
          </p>
          {/* AI */}
          <p
            style={{ backgroundColor: secondaryColor, color: getTextColor(secondaryColor) }}
            className="max-w-[70%] p-3 text-dark-text rounded-lg rounded-bl-none self-start"
          >
            How have you used customer insights to enhance products or services? Please use your microphone to answer the question. 🎙️
          </p>
          {/* User */}
          <div
            style={{ backgroundColor: primary2Color }}
            className="max-w-[50%] w-full gap-2.5 flex items-center justify-between px-3 text-white rounded-br-none rounded-lg min-h-11 self-end"
          >
            <button type="button" className="p-1 rounded-full bg-white/20">
              <IconPlayerPlayFilled size={18} />
            </button>
            <div className="flex items-center w-full h-1 rounded-full bg-white/40">
              <button type="button" className="bg-white rounded-full size-2.5"></button>
            </div>
            <button type="button">
              <IconVolume size={20} />
            </button>
          </div>

        </div>
        {/* voice box */}
        {openVoiceBox
          && (
            <div
              style={{ backgroundColor: primary2Color }}
              className="absolute text-white flex flex-col justify-center overflow-hidden items-center bottom-0 left-0 right-0 w-full h-[45%]"
            >
              <div className="relative">
                <button type="button" className="absolute -top-16 -right-24" onClick={() => setOpenVoiceBox(false)}>
                  <IconX size={26} />
                </button>
                <div className="absolute top-0 bottom-0 left-0 right-0 w-40 h-40 mx-auto mt-3 -translate-y-1/2 rounded-full bg-white/20"></div>
                <p className="text-lg flex items-center gap-1.5">
                  <span className="p-1 border-2 border-white rounded-full">
                    <IconPlayerStop size={20} />
                  </span>
                  Tap to stop recording
                </p>
              </div>
            </div>
          )}
      </div>

      {!openVoiceBox && (
        <div className="flex items-center w-full gap-2 mx-auto mt-2">
          {!showVoiceButton
            ? (
              <div className="relative w-full">
                <IconMoodSmileBeam size={22} className="absolute z-10 -translate-y-1/2 text-light-gray left-3 top-1/2" />
                <Input type="text" placeholder="Type message" className="w-full pl-12 bg-white rounded-full" />
              </div>
            ) : (
              <button
                style={{ backgroundColor: primary1Color }}
                type="button"
                className="flex items-center justify-center w-full h-10 text-white rounded-full"
                onClick={() => setOpenVoiceBox(true)}
              >
                <IconMicrophone size={20} />
              </button>
            )}
          <div className="flex items-center gap-1">
            <button
              style={{ backgroundColor: primary2Color }}
              type="button"
              className="p-2 text-white rounded-full"
            >
              <IconPhoto size={20} />
            </button>
            {!showVoiceButton ? (
              <button
                style={{ backgroundColor: primary1Color }}
                type="button"
                className="p-2 text-white rounded-full"
                onClick={() => setShowVoiceButton(true)}
              >
                <IconMicrophone size={20} />
              </button>
            ) : (
              <button
                style={{ backgroundColor: primary2Color }}
                type="button"
                className="p-2 text-white rounded-full"
                onClick={() => setShowVoiceButton(false)}
              >
                <IconKeyboard size={20} />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectBrandingChatPreview;
